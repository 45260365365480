import React from "react";
import { SelectPicker, CheckPicker } from "rsuite";
import { formCfg } from "../config";
import { playFetch } from "../../../lib/playfetch";
import { getEnvironments, getProjectId } from "../../Authentication";
import { projectTypes } from "../../../config/app";
import { find } from "lodash";

const QueryPicker = ({...props}) => {
    const [queryData, setQueryData] = React.useState([]);

    const queryChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange(value, props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const queryCleanHandler = (value, event) => {
        props.onChange(null, props.name);
    };

    React.useEffect(() => {
        async function initData() {
            let project = props.queryProject
            if (project === undefined) {
                if (getProjectId() === projectTypes.CORE) {
                    project = "prod-winter-core"
                } else {
                    const authEnvs = await getEnvironments(true);
                    let obj = find(authEnvs?.core, {'type': "LIVE"})
                    if (obj === undefined) {
                        obj = find(authEnvs?.core, {'type': "PROD"})
                    }
                    project = obj.label + `-betcloud-${getProjectId()}`
                }
            }

            const formData = new FormData();
            formData.append("dbquery", props.queryInput.split('/')[0])

            const apiUrl = `core/dbquery/readonly/${project}`
            playFetch(apiUrl, "POST", formData)
                .then(data => {
                    const options = data.map((item) => {
                        return { value: item[props.queryInput.split('/')[1]], label: item[props.queryInput.split('/')[1]] }
                    })
                    setQueryData(options)
                });
        }
        initData();
    }, [props.queryInput, props.queryProject]);

    return (
        <>
            {(props.multiPick === false) &&
            <SelectPicker
                data={queryData}
                disabled={props.disabled}
                plaintext={props.plaintext}
                onChange={queryChangeHandler}
                onClean={queryCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
            {(props.multiPick === true) &&
            <CheckPicker
                data={queryData}
                disabled={props.disabled}
                plaintext={props.plaintext}
                onChange={queryChangeHandler}
                onClean={queryCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
        </>
    );
};

export default QueryPicker;
