import { find } from "lodash";

export const isProdEnv = (name, environment) => {
    const env = find(environment, ['label', name])
    if (env === undefined) {
        return false
    } else if (env.type === 'LIVE' || env.type === 'PROD') {
        return true
    }
}

export const isLiveEnv = (name, environment) => {
    const env = find(environment, ['label', name])
    if (env === undefined) {
        return false
    } else if (env.type === 'LIVE') {
        return true
    }
}

export const isReadOnlyEnv = (name, environment) => {
    const env = find(environment, ['label', name])
    if (env === undefined) {
        return true
    } else {
        return env.is_read_only
    }
}

export const mapEnvPickerTypeToGitopsGroup = (type) => {
    if (type === "PROD" || type === "LIVE") {
        return "production"
    } else {
        return "nonprod"
    }
}
