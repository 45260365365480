import { commonCfg } from "../../Common/config";

export const quickLinksCfg = {
    KEYS: [
        {
            name: "top_level",
            width: 2,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "order",
            width: 1,
            withBookWidth: 1,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "name",
            width: 3,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "url",
            width: 10,
            withBookWidth: 10,
            isReadOnly: true,
            isMetaData: false
        },
        {
            name: "start_date",
            width: 3,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "end_date",
            width: 3,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        }
    ],
    ACTIONWIDTH: 2,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.UPDATE,
        commonCfg.OPERATIONS.DELETE
    ]
}