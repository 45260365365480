import Records from '../../components/Records';
import Header from '../../components/Header';

const Jobs = ({...props}) => {
	return (
		<>
            <Header />
			<div className='main'>
				<div className='area' style={{ width: "100%" }} >
                    <Records
                        props={{
                            id: "jobs",
                            apiUrl: "generic/jobs",
                            type: props.type,
                            subtype: props.subtype
                        }}
                    />
				</div>
			</div>
		</>
	);
};

export default Jobs;