import React from "react";
import { Popover, Dropdown } from 'rsuite';

const GitopsMenu = React.forwardRef(({ onSelect, ...rest }, ref) => (
	<Popover
		arrow={false}
		ref={ref}
		{...rest}
		full
		style={{ margin: "-2px",
				 borderRadius: "20px",
				 border: "5px solid gray",
				 backgroundColor: "#f5f4f2" }}
	>
		<Dropdown.Menu
			onSelect={onSelect}
			style={{ borderRadius: "15px",
					 backgroundColor: "#f5f4f2" }}
		>
			<Dropdown.Item eventKey="approvals">Approvals</Dropdown.Item>
			<Dropdown.Item eventKey="verify_sha">Verify Sha</Dropdown.Item>
			<Dropdown.Item eventKey="manual_refresh">Repo Refresh</Dropdown.Item>
		</Dropdown.Menu>
	</Popover>
));

export default GitopsMenu;