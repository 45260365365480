import React from "react";
import { Drawer, Nav } from "rsuite";
import Sha from "./Sha";
import Secret from "./Secret";

const Navbar = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 20 }}>
            <Nav.Item eventKey="sha">Commit Sha</Nav.Item>
            <Nav.Item eventKey="secret">Secrets</Nav.Item>
        </Nav>
    );
};

const Promote = ({ ...props }) => {
    const [activeTab, setActiveTab] = React.useState('sha');

    return (
        <>
            <Drawer
                open={props.open}
                onClose={props.onClose}
                size="lg"
                backdrop="static"
            >
                <Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        {`Promote from ${props.group}/${props.env} environment`}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <Navbar appearance="tabs" active={activeTab} onSelect={setActiveTab} />
                    {(activeTab === "sha") && <>
                        <Sha {...props} />
                    </>}
                    {(activeTab === "secret") && <>
                        <Secret {...props} />
                    </>}
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default Promote;
