import React from "react";
import { CheckPicker, SelectPicker } from "rsuite";
import { formCfg } from "../config";

const SimplePicker = ({ ...props }) => {

    const compare = (a, b) => {
        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    const envNameChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            if (Array.isArray(value)) {
                props.onChange(value, props.name);
            } else {
                const envs = []
                envs.push(value)
                props.onChange(envs, props.name);
            }
        }
    };

    const envNameCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };

    return (
        <>
            {(props.multiPick === true) ?
                <CheckPicker
                    data={props.data}
                    groupBy={props.groupBy}
                    onChange={envNameChangeHandler}
                    onClean={envNameCleanHandler}
                    value={props.value}
                    sort={isGroup => {
                        if (isGroup) {
                            return (a, b) => {
                                return compare(a.groupTitle, b.groupTitle);
                            };
                        }
                        return (a, b) => {
                            return compare(a.label, b.label);
                        };
                    }}
                    style={{ width: (props.width === undefined) ? formCfg.WIDTH : props.width}} /> :
                <SelectPicker
                    data={props.data}
                    groupBy={props.groupBy}
                    onChange={envNameChangeHandler}
                    onClean={envNameCleanHandler}
                    sort={isGroup => {
                        if (isGroup) {
                            return (a, b) => {
                                return compare(a.groupTitle, b.groupTitle);
                            };
                        }
                        return (a, b) => {
                            return compare(a.label, b.label);
                        };
                    }}
                    style={{ width: (props.width === undefined) ? formCfg.WIDTH : props.width}} />
            }
        </>
    );
};

export default SimplePicker;