import React, { useState, useEffect } from 'react';
import { CheckPicker } from 'rsuite';
import { playbooks } from "../../config/playbooks";
import { getUserRole } from '../../components/Authentication';
import PlaybookSteps from './steps';

const Manual = ({ props }) => {
    const [showSteps, setShowSteps] = useState(false)
    const [stepIds, setStepIds] = useState([])
    const [playSteps, setPlaySteps] = useState([])

    useEffect(() => {
        const stepData = playbooks[props.playbookId].steps.map((item, index) => {
            const checkRoles = (item.roles === undefined) ? playbooks[props.playbookId].roles : item.roles
			if (getUserRole().some(role => checkRoles.includes(role))) {
				return ({ label: item.name, value: index });
			}
			return undefined;
		}).filter(Boolean);
		setPlaySteps(stepData)
	}, [props.playbookId]);

    const stepChangeHandler = (value, event) => {
        if (value === null) {
            setStepIds([])
            setShowSteps(false)
        } else {
            setStepIds(value)
            setShowSteps(true)
        }
    };

    const stepCleanHandler = (event) => {
        setStepIds([])
        setShowSteps(false)
    }
 
    return (
        <div style={{ marginTop: 10 }}>
            <CheckPicker
                label="Steps"
                block
                onChange={stepChangeHandler}
                onClean={stepCleanHandler}
                data={playSteps} />
            {showSteps && <PlaybookSteps props={{ playbookId: props.playbookId, stepIds: stepIds, setHelpText: props.setHelpText }} />}
        </div>
    ); 
};

export default Manual;