import React from "react";
import { FlexboxGrid } from "rsuite";
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Sha from "./sha";

const App = ({...props}) => {
    const [liveData, setLiveData] = React.useState({});

    React.useEffect(() => {
        setLiveData(filter(props.data, ['live', true])[0]);
    }, [props.data])

    return (
		<>
			<div style={{ marginBottom: props.marginBottom, display: "flex" }} >
				<div style={{ flex: 3, border: "2px solid gray", borderBottomLeftRadius: "16px", backgroundColor: "#8af0c9" }} >
					<div style={{ marginLeft: "20px" }} >
						<FlexboxGrid justify="start" align="middle">
							<FlexboxGrid.Item colspan={24}>
								<b>{props.name}</b>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</div>
				</div>
				<div style={{ flex: 2 }} >
					<div>
						<FlexboxGrid justify="center" align="middle">
							<FlexboxGrid.Item colspan={24}>
								<div style={{ borderTop: "2px solid gray",
											  borderBottom: "2px solid gray",
											  backgroundColor: "#fff6c9",
											  color: "black",
											  display: "flex",
                                              textAlign: "center",
											  alignItems: "center",
											  justifyContent: "center" }}>
                                    {(isEmpty(liveData) === false) &&
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                    alignContent: "center",
                                                    backgroundColor: 'red',
                                                    width:'20px',
                                                    height:'20px',
                                                    borderRadius: '20px',
                                                    color: 'white',
                                                    marginRight: "5px"
                                                }}
                                            >
                                                L
                                            </div>
                                    }
									{(get(liveData, "forceUpdate", false)) &&
                                            <div
                                                style={{
                                                    display: "inline-block",
                                                    alignContent: "center",
                                                    backgroundColor: 'orange',
                                                    width:'20px',
                                                    height:'20px',
                                                    borderRadius: '20px',
                                                    color: 'black'
                                                }}
                                            >
                                                F
                                            </div>
                                    }
								</div>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</div>
				</div>
				<div style={{ flex: 4 }} >
					<FlexboxGrid justify="end" align="middle">
						<FlexboxGrid.Item colspan={24}>
							<div style={{ border: "2px solid gray",
										  backgroundColor: "#8af0c9",
										  borderBottomRightRadius: "16px",
										  borderTopRightRadius: "16px",
										  display: "flex",
										  alignItems: "center",
										  justifyContent: "right" }}>
								<Sha data={liveData} manifest={props.data} />
							</div>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</div>
			</div>
		</>
	);
};

export default App;