import React, { useEffect, useState } from "react";
import { Stack, Button, Divider } from 'rsuite';
import DbForm from "./dbform";
import ApprovedJobs from "./approvedjobs";
import { getUserRole } from "../Authentication";

const DbAccess = ({...props}) => {
    const [approvalId, setApprovalId] = useState("");
    const [dbQuery, setDbQuery] = useState("");
    const [envName, setEnvName] = useState([]);
    const [remarks, setRemarks] = useState("");
    const [selectApprovedJobs, setSelectApprovedJobs] = useState(false);
    const [prefilledJob, setPrefilledJob] = useState(false);

    useEffect(() => {
        if ((dbQuery.length !== 0) &&
            (envName.length !== 0) &&
            (remarks.length !== 0)) {
            setPrefilledJob(true)
        }
    }, [dbQuery, envName, remarks]);

    const reset = () => {
        setPrefilledJob(false)
        setEnvName([])
        setDbQuery("")
        setRemarks("")
        setApprovalId("")
    };

    return (
        <>
            {(getUserRole().some(role => (role === "DB-RW")) === true) && <>
                <Stack justifyContent="flex-start">
                    <Button
                        color="orange"
                        size="md"
                        appearance="primary"
                        onClick={() => setSelectApprovedJobs(true)}
                        style={{ borderRadius: "20px" }}
                    >
                        <div style={{ color: "black" }} >
                            <b>{"Pre-fill approved requests"}</b>
                        </div>
                    </Button>
                </Stack>
                <Divider style={{ marginTop: "10px" }} />
            </>}
            <DbForm
                prefilledJob={prefilledJob}
                envName={envName}
                dbQuery={dbQuery}
                remarks={remarks}
                approvalId={approvalId}
                resetMe={() => reset()}
            />
            <ApprovedJobs
                openModal={selectApprovedJobs}
                closeMe={() => setSelectApprovedJobs(false)}
                setEnvName={setEnvName}
                setQuery={setDbQuery}
                setRemarks={setRemarks}
                setApprovalId={setApprovalId}
            />
        </>
    );
};
export default DbAccess;
