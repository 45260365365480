import React from "react";
import { SelectPicker } from "rsuite";
import { formCfg } from "../config";
const withdrawalStatusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Processed", label: "Processed" },
    { value: "Rejected", label: "Rejected" },
    { value: "Approved", label: "Approved" },
];
const WithdrawalStatusPicker = ({ ...props }) => {
    const withdrawalStatusChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            props.onChange(value, props.name);
        }
    };
    const withdrawalStatusCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };
    return (
        <SelectPicker
            data={withdrawalStatusOptions}
            onChange={withdrawalStatusChangeHandler}
            onClean={withdrawalStatusCleanHandler}
            style={{ width: formCfg.WIDTH }}
        />
    );
};
export default WithdrawalStatusPicker;
