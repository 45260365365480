import React from "react";
import Display from "../../Common/display";
import { featuredRaceCfg } from "./config";
import { fetch } from "../../Common/lib/fetch";
import Operation from "../operation";
import Create from "../Create";
import { commonCfg } from "../../Common/config";
import { envType } from "../../../FormGroup/config";

const FeaturedRace = ({...props}) => {
    const keyNames = featuredRaceCfg.KEYS.map(key => key.name)

    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch/${env}`}
        })

        const data = await fetch(featuredRaceCfg.KEYS.map(key => key.name), requestData);
        return data;
    }

    const renderOperation = (envName, refetch, record, readOnly, operation) => {
        return <Operation
                    {...props}
                    keys={featuredRaceCfg.KEYS}
                    actionWidth={featuredRaceCfg.ACTIONWIDTH}
                    refetchData={refetch}
                    envName={envName}
                    readOnly={readOnly}
                    operation={operation}
                    record={record}
                />
    }

    const renderDelete = (envName, refetch, record) => {
        return renderOperation(envName, refetch, record, true, commonCfg.OPERATIONS.DELETE)
    }

    const renderUpdate = (envName, refetch, record) => {
        return renderOperation(envName, refetch, record, false, commonCfg.OPERATIONS.UPDATE)
    }

    const renderCreate = (refetch) => {
        return <Create
                    {...props}
                    title="Add featured race"
                    keys={featuredRaceCfg.KEYS}
                    keyNames={keyNames}
                    open={true}
                    onClose={refetch}
                />
    }

    return (
        <Display
            {...props}
            displayEnv={false}
            selectEnv={true}
            showEnvPickerAsTab={true}
            envTypes={[envType.CORE]}
            colWidth={featuredRaceCfg.KEYS.map(key => key.width)}
            actionWidth={featuredRaceCfg.ACTIONWIDTH}
            keys={keyNames}
            operations={featuredRaceCfg.OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={false}
            addGlobally={true}
            renderCreate={(envName, refetch) => renderCreate(refetch)}
            renderUpdate={(envName,refetch, records) => renderUpdate(envName,refetch, records)}
            renderDelete={(envName, refetch, records) => renderDelete(envName, refetch, records)}
        />
    );
};
export default FeaturedRace;