import React from "react";
import { Form, Schema, Stack, Message, useToaster, FlexboxGrid } from 'rsuite';
import Confirm from "../Common/confirm";
import find from 'lodash/find';
import EnvPicker from "../../FormGroup/EnvPicker";
import { playFetch } from "../../../lib/playfetch";

const envNameRule = Schema.Types.ArrayType().isRequired('This field is required.');

const Update = ({...props}) => {
    const toaster = useToaster();
    const [envName, setEnvName] = React.useState([]);

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const updateRecord = () => {
        Promise.all(envName.map(env => {
                const record = find(props.records.children, ['name', env])
                return playFetch(`${props.apiUrl}/offer/${record.name}/${record.id}`)
            }))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                ).finally(() => {
                    props.refetchData()
                });
    }

    return (
        <>
            <Stack spacing={20} justifyContent="center">
                <div>
                    <Form>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <Form.Group controlId="envName">
                                    <Form.Control
                                        name="envName"
                                        accepter={EnvPicker}
                                        cascade={true}
                                        value={envName}
                                        multiPick={true}
                                        type={['core/ALL', 'wincore/ALL']}
                                        exclude={props.excludedEnvs}
                                        label="Environment"
                                        onChange={envNameChangeHandler}
                                        rule={envNameRule}
                                        width={250}
                                    />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Confirm onConfirm={updateRecord} onCancel={props.refetchData} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </div>
            </Stack>
        </>
    );
};
export default Update;
