import React, { useState } from "react";
import { Stack, Input, FlexboxGrid, Panel, ButtonToolbar, ButtonGroup, IconButton } from 'rsuite';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

const AddKey = ({...props}) => {
	const [keyName, setKeyName] = useState('');
    const [keyValue, setKeyValue] = useState('');

    const addHandler = (event) => {
        event.preventDefault();
        props.onAdd(keyName, keyValue);
    };

    const closeHandler = (event) => {
        event.preventDefault();
        props.onClose();
    };

	const keyNameHandler = (value, event) => {
		event.preventDefault();
		setKeyName(value);
	};

    const keyValueHandler = (value, event) => {
        event.preventDefault();
		setKeyValue(value);
	};

	return (
        <Panel
            shaded
            style={{ backgroundColor: "white" }}
        >
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={5}>
                <div style={{ color: "#a83800", fontWeight: "bold" }}>Enter UDID:</div>
		        <Input
                    style={{
						outline: "none",
						border: "1px solid gray",
						borderRadius: "20px",
                        "&:focus": {
                            outline: "none"
                        },
                        marginBottom: "15px",
                        width: "500px"
                    }}
                    value={keyName}    
					onChange={keyNameHandler}
                />

                <div style={{ color: "#a83800", fontWeight: "bold" }}>Enter Remarks:</div>
                <Input
                    style={{
						outline: "none",
						border: "1px solid gray",
						borderRadius: "20px",
                        "&:focus": {
                            outline: "none"
                        },
                        marginBottom: "15px",
                        width: "500px"
                    }}
                    value={keyValue}
					onChange={keyValueHandler}
                />
            </Stack>
            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={3}>
                    <ButtonToolbar>
                        <ButtonGroup>
                            <IconButton
                                icon={<CheckIcon color="green" />}
                                size="sm"
                                onClick={addHandler}
                                disabled={keyName.length === 0}
                                style={{
                                    border: "1px solid gray",
                                    borderTopLeftRadius: "20px",
                                    borderBottomLeftRadius: "20px"
                                }}
                            />
                            <IconButton
                                icon={<CloseIcon color="red" />}
                                size="sm"
                                onClick={closeHandler}
                                style={{
                                    border: "1px solid gray",
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px"
                                }}
                            />
                        </ButtonGroup>
                    </ButtonToolbar>
                </FlexboxGrid.Item>
		    </FlexboxGrid>
        </Panel>
	);
};

export default AddKey;