import React, { useEffect, useState } from "react";
import { Stack, Panel, FlexboxGrid, Button } from "rsuite";
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import { padStart } from "lodash";

const Plan = ({...props}) => {
	const [status, setStatus] = useState("orange");
	const [statusElement, setStatusElement] = useState(<React.Fragment/>);
	const [createdOn, setCreatedOn] = useState(new Date());

	useEffect(() => {
		let statusText = "";
		setCreatedOn(new Date(props.data.created_on))
		let element = <InfoRoundIcon color="orange" />
		if (props.data.state.name === "COMPLETED") {
			statusText = `${props.data.state.result.name}`
			if (props.data.state.result.name === "SUCCESSFUL") {
				setStatus("green")
				element = <CheckRoundIcon color="green" />
			} else if (props.data.state.result.name === "FAILED") {
				setStatus("red")
				element = <WarningRoundIcon color="red" />
			}
		} else {
			statusText = `${props.data.state.stage.name}`
		}
		setStatusElement(<>{element}&nbsp;&nbsp;{statusText}</>)
	}, [props.data]);

	return (
		<Panel bordered style={{ borderLeft: `5px solid ${status}`, borderRight: `5px solid ${status}`, marginBottom: "10px" }}>
			<FlexboxGrid>
				<FlexboxGrid.Item colspan={4}>
					<Stack spacing={5} direction="column" alignItems="flex-start" justifyContent="flex-start">
						<Stack>
							<b>Build:</b>&nbsp;#{props.data.build_number}
						</Stack>
						<Stack>
							<b>Creator:</b>&nbsp;{props.data.creator.display_name}
						</Stack>
					</Stack>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={8}>
					<Stack spacing={5} direction="column" alignItems="flex-start" justifyContent="flex-start">
						<Stack>
							<b>{props.data.target.ref_type}:</b>&nbsp;{props.data.target.ref_name}
						</Stack>
						<Stack>
							<b>commit:</b>&nbsp;
							<a href={props.data.target.commit.links.html.href} rel="noreferrer" target="_blank">
								{props.data.target.commit.hash}
							</a>
						</Stack>
					</Stack>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={6}>
					<Stack spacing={5} direction="column" alignItems="flex-start" justifyContent="flex-start">
						<Stack>
							<b>Started:</b>&nbsp;{createdOn.toDateString() +
                                                    ", " +
                                                    padStart(createdOn.getHours(), 2, '0') +
                                                    ":" +
                                                    padStart(createdOn.getMinutes(), 2, '0') +
                                                    ":" +
                                                    padStart(createdOn.getSeconds(), 2, '0')
                                                }
						</Stack>
						<Stack>
							<b>Duration:</b>&nbsp;
							{`${props.data.duration_in_seconds/60|0}m ${props.data.duration_in_seconds%60}s`}
						</Stack>
					</Stack>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={3}>
					<Stack spacing={5} direction="column" alignItems="flex-start" justifyContent="flex-start">
						<Stack>
							{statusElement}
						</Stack>
						<Stack>
							<React.Fragment />
						</Stack>
					</Stack>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={3}>
					<Stack spacing={5} direction="column" alignItems="flex-end" justifyContent="flex-start">
						<Stack>
							<React.Fragment />
						</Stack>
						<Stack>
							<Button
								href={props.data.href}
								rel="noreferrer"
								target="_blank"
								size="md"
								appearance="ghost"
								style={{ borderRadius: "20px" }}>
								<div style={{ color: "#000000" }} >
									{((props.data.state.name !== "COMPLETED") && (props.readOnly === false)) ? "Review & Apply" : "View"}
								</div>
					 		</Button>
						</Stack>
					</Stack>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		</Panel>
	);
};

export default Plan;