import { commonCfg } from "../Common/config";

export const apiUsersCfg = {
    KEYS: [
        {
            name: "email",
            width: 6
        },
        {
            name: "roles",
            width: 10
        }
    ],
    ACTIONWIDTH: 8,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.UPDATE,
        commonCfg.OPERATIONS.DELETE
    ]
}