import { useEffect, useRef } from "react";
import { JSONEditor } from "vanilla-jsoneditor";

const VanillaJsonEditor = ({ props }) => {
    const refContainer = useRef(null);
    const refEditor = useRef(null);

    useEffect(() => {
        const options = {
            mode: 'text',
            mainMenuBar: false
        };

        if(refContainer.current) {
            refEditor.current = new JSONEditor({
                                        target: refContainer.current,
                                        props: options
            });
        }

        return () => {
            if (refEditor.current) {
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (refEditor.current) {
            refEditor.current.updateProps(props);
        }
    }, [props]);

    return <div style={{ display: "flex", flex: 1 }} ref={refContainer}></div>;
}

export default VanillaJsonEditor;
