import React from "react";
import { Popover, Whisper } from "rsuite";
import ReactJson from 'react-json-view';

const Sha = ({...props}) => {
    const speaker = <Popover>
                        <div style={{
                                border: "5px solid black",
                                borderRadius: "24px",
                                padding: "10px",
                                width: "420px"
                            }}
                        >
                            <ReactJson
                                src={props.manifest}
                                name={false}
                                theme="shapeshifter:inverted"
                                indentWidth={2}
                                collapsed={2}
                                enableClipboard={true}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                sortKeys={false}
                            />
                        </div>
                    </Popover>
    return (
		<>
            {(props.manifest.length === 0) ? "FETCH-ERR":
            <Whisper
                preventOverflow
				placement="autoHorizontal"
				trigger="click"
				controlId="app-sha"
				speaker={speaker}
				enterable
			>
                <div
                    style={{
					    cursor: "pointer",
					    "&:hover": {
						    cursor: "pointer"
					    }
				    }}
                >
                    {(props.data === undefined) ? "NOT-LIVE": `${props.data.binaryVersion}-${props.data.jsVersion}`}
                </div>
            </Whisper>}
            &nbsp;&nbsp;
		</>
	);
};

export default Sha;