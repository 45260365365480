import React from "react";
import { Button, Message, toaster } from "rsuite";
import Display from "../../Common/display";
import { manualCfg } from "./config";
import { fetch } from "../../Common/lib/fetch";
import { playFetch } from "../../../../lib/playfetch";
import { envType } from "../../../FormGroup/config";

const Manual = ({...props}) => {
    const [jobKeys, setJobKeys] = React.useState(manualCfg.KEYS.map(key => key.name))

    React.useEffect(() => {
        let newJobKeys = manualCfg.KEYS.map(key => key.name)
		if (props.subtype === "pending") {
            newJobKeys[newJobKeys.indexOf("updated_at")] = "submitter_ip_addr"
        }
        setJobKeys(newJobKeys)
	}, [props.subtype]);

    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/manual/fetch/${props.subtype}/${env}`}
        })

        const data = await fetch(manualCfg.KEYS.map(key => key.name), requestData);
        return data;
    }

    const renderStatus = (row) => {
        if (row.is_executed) {
            return "Executed"
        } else if (row.is_executing) {
            return "Executing"
        } else if (row.is_approved) {
            return "Approved"
        } else {
            return "Submitted"
        }
    }

    const discardHandler = (approval_id, refetch) => {
        const apiUrl = `generic/jobs/approval/delete/${approval_id}`
        
        playFetch(apiUrl)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                    if(data.status === 200) {
                        msg_type = 'success';
                        msg = data.msg;
                    } else {
                        msg = `${data.detail.msg}`
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            <b>{msg}</b>
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 30000 })
                }).finally(() => {
                    refetch()
                });
    }

    const renderCustomAction = (row, refetch) => {
        return (
            <>
                {((props.subtype === "completed") || (row.is_executing === true)) && <div
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        border: "1px grey solid",
                        borderRadius: "10px",
                        margin: "-1px"
                    }}
                >
                    {renderStatus(row)}
                </div>}
                {((props.subtype === "pending") && (row.is_executing === false)) && <Button
                    color="orange"
                    size="xs"
                    appearance="ghost"
                    onClick={() => discardHandler(row.approval_id, refetch)}
                    style={{ borderRadius: "20px", borderWidth: "2px", borderColor: "black" }}>
                    <div style={{ color: "#4C4E52" }} >
                        <b>&nbsp;Discard&nbsp;</b>
                    </div>
                </Button>}
            </>
        );
    }

    return (
        <>
            <Display
                {...props}
                displayEnv={true}
                selectEnv={false}
                showEnvPickerAsTab={true}
                envTypes={[envType.CORE, envType.WINCORE, envType.SREPLAYBOOK]}
                colWidth={manualCfg.KEYS.map(key => key.width)}
                bookieWidth={4}
                actionWidth={manualCfg.ACTIONWIDTH}
                keys={jobKeys}
                operations={manualCfg.OPERATIONS}
                fetchData={(envName) => fetchData(envName)}
                displayActionRecord={true}
                addGlobally={true}
                renderCustomAction={(row, refetch) => renderCustomAction(row, refetch)}
            />
        </>
    );
};
export default Manual;
