import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../Authentication";
import { appCfg } from "../../config/app";
import { getUserRole } from "../Authentication";

const Protect = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/" replace />;
    }

    if ((children.key === 'database') &&
        (getUserRole().some(role => appCfg.DATABASE_ALLOWED_ROLES.includes(role)) !== true)) {
        return <Navigate to="/" replace />;
    }

    if ((children.key === 'jobs') &&
        ((getUserRole().some(role => ["DB-RW", "DB-Approvers"].includes(role)) !== true))) {
        return <Navigate to="/" replace />;
    } else if ((children.key === 'jobs') &&
                (children.props.type === 'approval') &&
                ((getUserRole().some(role => ["DB-Approvers"].includes(role)) !== true))) {
        return <Navigate to="/" replace />;
    }

    if ((children.key === 'deployment') &&
        (getUserRole().some(role => appCfg.GITOPS_ALLOWED_ROLES.includes(role)) !== true)) {
        return <Navigate to="/" replace />;
    }
    return children;
};

export default Protect;