import React from "react";
import { Divider, Toggle } from "rsuite";
import { srcBetcloudLogo } from "../../assets/logos";
import Navbar from "../../components/Navbar";
import { isAuthenticated } from "../Authentication";
import { projectTypes } from "../../config/app";
import { toTitleCase } from "../../lib/utils";

const Header = ({...props}) => {
    return (
        <div style={{ position: "sticky", top: 0, zIndex: 999, backgroundColor: "white" }} >
		    <center>
			    <div style={{ marginTop: 10, marginBottom: 10 }} >
				    <img src={srcBetcloudLogo} alt="Betcloud" />
			    </div>
			    <div style={{ position: "relative", top: "0" }}>
				    <h4 style={{ color: "green" }}>
					    Application Support Tool
				    </h4>
			    </div>
			    <Divider style={{ marginTop: 10, marginBottom: 5 }} />
                {(isAuthenticated()) ?
                    <Navbar /> :
                    <div style={{ display: "inline-block", position: "absolute", right: "20px", top: "45px" }} >
                    <Toggle
                        size="lg"
                        checkedChildren={`${toTitleCase(projectTypes.CASINO)} Project`}
                        unCheckedChildren={`${toTitleCase(projectTypes.CORE)} Project`}
                        checked={(props.projectType === projectTypes.CORE) ? false : true}
                        onChange={props.changeProjectType}
                        plaintext={false}
                        style={{
                            "--rs-toggle-checked-bg": "#c73f00",
                            "--rs-toggle-checked-hover-bg": "#a83800",
                            "--rs-toggle-bg": "#008000",
                            "--rs-toggle-hover-bg": "#14540d"
                        }}
                    />
                    </div>
                }
		    </center>
        </div>
	);
};

export default Header;