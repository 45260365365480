import React from "react";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { uniq, filter } from "lodash";
import Display from "../Common/display";
import { offeringsCfg } from "./config";
import { fetch } from "../Common/lib/fetch";
import concat from 'lodash/concat';
import { envType } from "../../FormGroup/config";
import Update from "./update";
import { getEnvironments } from "../../Authentication";

const GlobalOfferings = ({...props}) => {
    const [excludedEnvs, setExcludedEnvs] = React.useState([]);
    const [authEnvs, setAuthEnvs] = React.useState([]);

    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch/${env}`}
        })

        const data = await fetch(offeringsCfg.KEYS.map(key => key.name), requestData);

        const goData = data.map(item => {
                            const childEnvs = uniq(item.children.map(x => x.name))
                            if(childEnvs.length === envName.length) {
                                return item;
                            } else {
                                return undefined;
                            }
                        }).filter(Boolean);

        return goData;
    }

    const renderUpdate = (envName, refetch, records) => {
        const childEnvs = new Set(records.children.map(item => {
                                    return item.name.split("-")[0]
                                }))
                                
        const allCoreEnvs = authEnvs.core.map(item => {
            return `${item.label}`
        })

        const allWinCoreEnvs = authEnvs.wincore.map(item => {
            return `${item.label}`
        })

        const excEnvs = uniq(concat(concat(allCoreEnvs, allWinCoreEnvs), excludedEnvs).filter(item => !childEnvs.has(item)));
        
        return <Update {...props} excludedEnvs={excEnvs} records={records} refetchData={refetch} />
    }

    React.useEffect(() => {
        async function fetchAuthEnvironments() {
            const data = await getEnvironments();
            setExcludedEnvs(filter(data.core, ['is_scaled_down', true]).map(i => i.label));
            setAuthEnvs(data);
        }
        fetchAuthEnvironments()
    }, [props.id])

    return (
        <>
        {(authEnvs.length === 0) && <div style={{ display: "grid", placeItems: "center" }}>
                <SpinnerIcon pulse style={{ fontSize: '5em', margin: "50px" }} />
        </div>}
        {(authEnvs.length !== 0) && <Display
            {...props}
            displayEnv={false}
            selectEnv={false}
            envTypes={[envType.CORE, envType.WINCORE]}
            excludedEnvs={excludedEnvs}
            colWidth={offeringsCfg.KEYS.map(key => key.width)}
            actionWidth={offeringsCfg.ACTIONWIDTH}
            keys={offeringsCfg.KEYS.map(key => key.name)}
            operations={offeringsCfg.OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={true}
            renderUpdate={(envName, refetch, records) => renderUpdate(envName, refetch, records)}
        />}
        </>
    );
};
export default GlobalOfferings;