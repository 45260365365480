import React, { useEffect, useState } from 'react';
import { Form, Button, Divider, Content, Footer } from "rsuite";
import FormGroup from '../FormGroup';
import FormResponse from '../FormResponse';
import { playFetch } from '../../lib/playfetch';
import { envType, formGroupType } from '../FormGroup/config';
import { toTitleCase } from '../../lib/utils';

const Search = ({ props }) => {
    const [envName, setEnvName] = useState([]);
    const [searchBy, setSearchBy] = useState('');
    const [searchText, setSearchText] = useState('');
    const [loadResult, setLoadResult] = useState(false);
    const [result, setResult] = useState([]);
    const [formError, setFormError] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState(true)

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const searchByChangeHandler = (value, event) => {
        setSearchBy(value);
    };

    const searchTextChangeHandler = (value, name) => {
        setSearchText(value);
        if((props.searchByData !== undefined) && (props.searchByData.length === 1)) {
            setSearchBy(props.searchByData[0].value)
        }
    };

    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const callApi = (envIndex) => {
        const suffixUrl = ((props.searchByData !== undefined) ? `/${searchBy.toLowerCase()}` : '') +
                            ((props.showEnv !== envType.NONE) ? `/${envName[envIndex]}` : '') +
                            ((props.searchByData !== undefined) ? `/${searchText}` : '')
        const apiUrl = props.apiUrl + suffixUrl;
        playFetch(apiUrl)
            .then(data => {
                /* convert data to array */
                let data_array = []
                if(Array.isArray(data)) {
                    data_array = data
                } else {
                    data_array.push(data)
                }

                let newResults = result;
                if(props.showEnv !== envType.NONE) {
                    newResults[envIndex] = data_array
                } else {
                    newResults[0] = data_array;
                }
                setResult(newResults);
                if (newResults.includes(undefined) === false) {
                    setLoadResult(false)
                }
            });
    }

    const submitHandler = (event) => {
        event.preventDefault();
        setResult(Array(envName.length).fill(undefined))
        setLoadResult(true);
        if(props.showEnv === envType.NONE) {
            callApi(null)
        } else {
            envName.map((item, index) => callApi(index))
        }
    };

    useEffect(() => {
        setEnvName([])
        setSearchBy('')
        setSearchText('')
        setResult([])
        setLoadResult(false)
        setSubmitDisabled(true)
    }, [props.id, props.superId, props.isHistory]);

    useEffect(() => {
        if (((envName.length > 0) || (props.showEnv === envType.NONE)) &&
            (((props.searchByData === undefined) || ((searchBy !== '') &&
            (searchText !== ''))))) {
                setSubmitDisabled(false)
        }
        setResult([])
        setLoadResult(false)
    }, [envName, searchBy, searchText, props.showEnv, props.searchByData]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled) || (result.length !== 0));

    return (
        <>
            <Content>
                { (props.feedStep === true) ? <React.Fragment /> : <Divider /> }
                <Form onCheck={handleFormCheck} >
                    {(props.showEnv !== envType.NONE) && <FormGroup
                        name="envName"
                        value={envName}
                        multiPick={!props.feedStep}
                        envType={props.showEnv}
                        exclude={props.excludedEnv}
                        label="Environment"
                        type={formGroupType.ENVPICKER}
                        onChange={envNameChangeHandler} />}
                    {((props.searchByData !== undefined) && (props.searchByData.length > 1)) && <FormGroup
                        name="searchBy"
                        value={searchBy}
                        playbookId={props.playbookId}
                        label="Select Type"
                        type={formGroupType.DATAPICKER}
                        required={true}
                        onChange={searchByChangeHandler}
                        data={props.searchByData} />}
                    {(props.searchByData !== undefined) && <FormGroup
                        name="searchText"
                        value={searchText}
                        playbookId={props.playbookId}
                        label={(props.searchByData.length === 1) ? props.searchByData[0].label : "Enter Text" }
                        type={formGroupType.TEXT}
                        required={true}
                        onChange={searchTextChangeHandler} />}
                    <Button
                        appearance="primary"
                        disabled={isSubmitDisabled}
                        onClick={submitHandler}
                        loading={loadResult}>
                            {toTitleCase(props.id)}
                    </Button>
                </Form>
                <Divider />
            </Content>
            <Footer >
                {((result.length !== 0) && (loadResult === false)) && <FormResponse
                                                                            name={envName}
                                                                            response={result}
                                                                            feedStep={props.feedStep}
                                                                            feeder={props.feeder} /> }
            </Footer>
        </>
    );
};

export default Search;
