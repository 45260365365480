import React from "react";
import { Message, useToaster } from 'rsuite';
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";

const Delete = ({...props}) => {
    const toaster = useToaster();
    
    const deleteRecord = () => {
        Promise.all(props.records.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.name}/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                ).finally(() => {
                    props.refetchData()
                });
    }

    return (
        <>
            <b>Are you sure to delete ?</b>
            <Confirm onConfirm={deleteRecord} onCancel={props.refetchData} />
        </>
    );
};
export default Delete;