import { commonCfg } from "../../Common/config";

export const approvalCfg = {
    KEYS: [
        {
            name: "remarks",
            width: 6,
            withBookWidth: 6,
            isReadOnly: true,
            isMetaData: false
        },
        {
            name: "submitted_by",
            width: 4,
            withBookWidth: 4,
            isReadOnly: true,
            isMetaData: false
        },
        {
            name: "created_at",
            width: 4,
            withBookWidth: 4,
            isReadOnly: true,
            isMetaData: false
        }
    ],
    ACTIONWIDTH: 4,
    OPERATIONS: [
        commonCfg.OPERATIONS.CUSTOM
    ]
}