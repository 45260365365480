import React from "react";
import { Row, FlexboxGrid, ButtonToolbar, Button, IconButton, Divider } from "rsuite";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import ReloadIcon from '@rsuite/icons/Reload';
import filter from 'lodash/filter';
import Group from "./group";
import Edit from "./edit";
import { playFetch } from "../../../lib/playfetch";
import { mobileGroups } from "./config";
import { getEnvironments } from "../../Authentication";

const Mobile = ({...props}) => {
    const [loading, setLoading] = React.useState(true);
    const [mobileData, setMobileData] = React.useState([]);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [authEnvironments, setAuthEnvironments] = React.useState({})

    const fetchData = () => {
		setLoading(true)        
        playFetch(`deployments/mobile/fetch`)
            .then(data => {
                setMobileData(data)
            }).finally(() => {
                setLoading(false)
            });
	};

    const reloadHandler = (event) => {
		event.preventDefault();
		fetchData()
	};

	React.useEffect(() => {
        async function initMobile() {
            const data = await getEnvironments()
            setAuthEnvironments(data)
		    fetchData()
        }
        initMobile()
        // eslint-disable-next-line
	}, []);

	return (
		<>
            <FlexboxGrid justify="end">
				<FlexboxGrid.Item colspan={24}>
					<div style={{ display: "flex",
								  alignItems: "center",
								  justifyContent: "right" }}>
						<ButtonToolbar>
                            <Button
								color="orange"
								size="md"
								appearance="primary"
								disabled={loading || props.readOnly || !(authEnvironments?.core?.length)}
                                onClick={() => setOpenUpdate(true)}
								style={{ borderRadius: "20px" }}>
									<div style={{ color: "#4C4E52" }} >
										Update
									</div>
							</Button>
							<Button
								color="cyan"
								size="md"
								appearance="primary"
								disabled={loading || props.readOnly || !(authEnvironments?.core?.length)}
                                onClick={() => setOpenUpload(true)}
								style={{ borderRadius: "20px" }}>
									<div style={{ color: "#4C4E52" }} >
										Build
									</div>
							</Button>
						    <IconButton
								icon={<ReloadIcon color="#4C4E52" spin={loading} />}
								circle
								size="md"
								color="cyan"
								disabled={loading}
								onClick={reloadHandler}
								appearance="primary" />
						</ButtonToolbar>
					</div>
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            {((loading === true) && (mobileData.length === 0)) &&
                <div style={{ display: "grid", placeItems: "center" }}>
                    <SpinnerIcon pulse style={{ fontSize: '5em', margin: "50px" }} />
                </div>
            }
            { (openUpload === true) && <>
					<Edit
                        type="upload"
						open={openUpload}
						onClose={() => setOpenUpload(false)}
					/>
				</>
			}
            { (openUpdate === true) && <>
					<Edit
                        type="update"
						open={openUpdate}
						onClose={() => setOpenUpdate(false)}
					/>
				</>
			}
            {(Object.values(mobileGroups).map(group =>
                <Row key={`row-${group}`} >
                    <Group
                        name={group}
                        readOnly={props.readOnly}
                        data={filter(mobileData, (obj) => { return group === obj.env.type })} />
                </Row>
            ))}
		</>
	);
};

export default Mobile;