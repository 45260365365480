import React, { useState } from "react";
import { InputGroup, Input, Toggle, FlexboxGrid, Panel, ButtonToolbar, ButtonGroup, IconButton } from 'rsuite';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

const AddKey = ({...props}) => {
	const [keyName, setKeyName] = useState('');
    const [keyValue, setKeyValue] = useState(false);

    const addHandler = (event) => {
        event.preventDefault();
        props.onAdd(keyName, (keyValue ? "True" : "False"));
    };

    const closeHandler = (event) => {
        event.preventDefault();
        props.onClose();
    };

	const keyNameHandler = (value, event) => {
		event.preventDefault();
		setKeyName(value.trim().toUpperCase());
	};

    const keyValueHandler = (checked, event) => {
		setKeyValue(checked);
	};

	return (
        <Panel
            shaded
            header={<div style={{ color: "#a83800", fontWeight: "bold" }}>Add new key:</div>}
            style={{ backgroundColor: "white" }}
        >
		<FlexboxGrid justify="space-between">
			<FlexboxGrid.Item colspan={18}>
				<InputGroup
					inside
                    size="sm"
					style={{
						outline: "none",
						border: "1px solid gray",
						borderRadius: "20px",
						"&:focus": {
							outline: "none"
						}
					}}
				>
					<Input style={{
								outline: "none",
								border: "1px solid gray",
								borderRadius: "20px" }}
                        value={keyName}    
						onChange={keyNameHandler}
                    />
                    <InputGroup.Button style={{ padding: 0, borderRadius: "16px"}}>
                        <Toggle
                            style={{
                                margin: 0,
                                "--rs-toggle-checked-bg": "#c73f00",
                                "--rs-toggle-checked-hover-bg": "#a83800"
                            }}
                            size="lg"
                            checked={keyValue}
                            onChange={keyValueHandler}
                        />
                    </InputGroup.Button>
				</InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3}>
                <ButtonToolbar>
                    <ButtonGroup>
                        <IconButton
                            icon={<CheckIcon color="green" />}
                            size="sm"
                            onClick={addHandler}
                            disabled={keyName.length === 0}
                            style={{
                                border: "1px solid gray",
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px"
                            }}
                        />
                        <IconButton
                            icon={<CloseIcon color="red" />}
                            size="sm"
                            onClick={closeHandler}
                            style={{
                                border: "1px solid gray",
                                borderTopRightRadius: "20px",
                                borderBottomRightRadius: "20px"
                            }}
                        />
                    </ButtonGroup>
                </ButtonToolbar>
            </FlexboxGrid.Item>
		</FlexboxGrid>
        </Panel>
	);
};

export default AddKey;