import React, { useEffect, useState } from "react";
import { Form, Schema, FlexboxGrid, Message, useToaster, Stack } from 'rsuite';
import get from 'lodash/get';
import Confirm from "../Common/confirm";
import CustomPicker from "../../FormGroup/CustomPicker";
import { playFetch } from "../../../lib/playfetch";
import { commonCfg } from "../Common/config";

const Operation = ({...props}) => {
    const toaster = useToaster();
    const [newRecord, setNewRecord] = useState({});
    const [rolePickerData, setRolePickerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [formError, setFormError] = useState({});

    const populateRolePickerData = () => {
        if (rolePickerData.length === 0) {
            playFetch("playbook/roles/fetch")
                .then(response => {
                    const data = response.data.map(item => {
                        return { "value" : item.name, "label": item.name }
                    })
                    setRolePickerData(data);
                })
        }
    }

    const changeHandler = (value, name) => {
        let newValue = value;
        let newObj = {}

        newObj[name] = newValue;
        setNewRecord({
            ...newRecord,
            ...newObj
        });
    }

    useEffect(() => {
        const nextRecord = {}
        if (props.record === undefined) {
            props.keys.forEach(key => {
                switch(key.name) {
                    case "roles":
                        nextRecord[key.name] = [];
                        break;
                    default:
                        nextRecord[key.name] = "";
                        break;
                }
            })
            setNewRecord(nextRecord)
        } else {
            setNewRecord(props.record)
        }
        if (rolePickerData.length === 0) {
            playFetch("playbook/roles/fetch")
                .then(response => {
                    const data = response.data.map(item => {
                        return { "value" : item.name, "label": item.name, "id": item.id }
                    })
                    setRolePickerData(data);
                })
        }
    }, [props.keys, props.record, rolePickerData]);

    useEffect(() => {
        let isSubmitDisabled = false
        for (const value of Object.values(newRecord)) {
            if (value?.length === 0) {
                isSubmitDisabled = true
            }
        }
        if (isSubmitDisabled === false) {
            isSubmitDisabled = (Object.keys(formError).length > 0)
        }
        setSubmitDisabled(isSubmitDisabled)
    }, [newRecord, formError]);

    const renderList = data => {
        const elem = data.map((item, index) =>
                            <div
                                key={`list-div-${index}`}
                                style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    border: "1px grey solid",
                                    borderRadius: "10px",
                                    margin: "-1px"
                                }}
                            >
                                {item}
                            </div>
                    )
        return (
            <Stack wrap spacing={6} justifyContent="center">
                {elem}
            </Stack>
        );
    }

    const renderInput = key => {
        switch(key) {
            case "email":
                const rule = Schema.Types.StringType()
                                            .isRequired('This field is required.')
                                            .isEmail('Please input the email address')
                                            .pattern(/^[\w-.]+@betcloud.com.au$/,'Please enter a valid email address with betcloud domain')
                return (
                    <Form.Control
                        name={key}
                        plaintext={(props.operation === commonCfg.OPERATIONS.CREATE) ? false : true}
                        value={get(newRecord, key, "")}
                        rule={rule}
                        errorPlacement='bottomStart'
                        onChange={(value, event) => changeHandler(value, key)}
                        style={{ width: "100%" }}
                    />
                )
            case "roles":
                if (props.readOnly === true) {
                    return renderList(get(newRecord, key, []))
                } else {
                    return (
                        <Form.Group controlId={key}>
                            <Form.Control
                                name={key}
                                data={rolePickerData}
                                accepter={CustomPicker}
                                multiPick={true}
                                plaintext={props.readOnly}
                                value={get(newRecord, key, [])}
                                onChange={(value, event) => changeHandler(value, key)}
                                onOpen={() => populateRolePickerData()}
                                onSearch={() => populateRolePickerData()}
                                style={{ width: "100%" }}
                            />
                        </Form.Group>
                    )
                }
            default:
                return (
                    <Form.Control
                        name={key}
                        plaintext={props.readOnly}
                        value={get(newRecord, key, "")}
                        onChange={(value, event) => changeHandler(value, key)}
                        style={{ width: "100%" }}
                    />
                )
        }
    }

    const addRecord = () => {
        const formData = new FormData();

        props.keys.forEach(key => {
            let value = get(newRecord, key.name, null);
            if(value !== null) {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        formData.append(key.name, item)
                    })
                } else {
                    formData.append(key.name, value);
                }
            }
        });

        const apiUrl = `${props.apiUrl}/add`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                if(data.status === 200) {
                    msg_type = 'success';
                    msg = data.msg;
                } else {
                    msg = `${data.detail.msg}`
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        <b>{msg}</b>
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 30000 })
            }).finally(() => {
                setLoading(false)
                props.refetchData()
            });
    }

    const updateRecord = (envName) => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        formData.append(key.name, item)
                    })
                } else {
                    formData.append(key.name, value);
                }
            }
        });

        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/update/${record.id}`, "POST", formData)))
                .then(allData => {
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = `${data.detail.msg}`
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                }).finally(() => {
                    setLoading(false)
                    props.refetchData()
                });
    }

    const deleteRecord = () => {
        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                ).finally(() => {
                    setLoading(false)
                    props.refetchData()
                });
    }

    const operationHandler = () => {
        setLoading(true)
        if (props.operation === commonCfg.OPERATIONS.CREATE) {
            addRecord();
        } else if (props.operation === commonCfg.OPERATIONS.UPDATE) {
            updateRecord();
        } else {
            deleteRecord();
        }
    };

    const errorHandler = (formError) => {
        setFormError(formError)
    };

    return (
        <>
            <Form onCheck={errorHandler}>
                <FlexboxGrid justify="space-between" className="add-records-row" style={{ display: "flex", margin: "5px" }}>
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={key.width} key={`add-${index}`}>
                            <div className="add-records-col" style={{ display: "block" }}>
                                {renderInput(key.name)}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`add-action`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            {(props.operation === commonCfg.OPERATIONS.DELETE) && <b>Are you sure to delete ?</b>}
                            <Confirm submitDisabled={submitDisabled} loading={loading}  onConfirm={operationHandler} onCancel={props.refetchData} />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );
};
export default Operation;
