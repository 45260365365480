import React, { useEffect, useState } from "react";
import { Form, FlexboxGrid, Message, useToaster} from 'rsuite';
import split from 'lodash/split';
import get from 'lodash/get';
import EnvPicker from "../../FormGroup/EnvPicker";
import CustomPicker from "../../FormGroup/CustomPicker";
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";
import { commonCfg } from "../Common/config";

const Operation = ({...props}) => {
    const toaster = useToaster();
    const [envName, setEnvName] = useState([]);
    const [newRecord, setNewRecord] = useState({});

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const changeHandler = (value, name) => {
        let newValue = value;
        let newObj = {}

        newObj[name] = newValue;
        setNewRecord({
            ...newRecord,
            ...newObj
        });
    }

    useEffect(() => {
        const nextRecord = {}
        if (props.record === undefined) {
            props.keys.forEach(key => {
                switch(key.name) {
                    case "state":
                        nextRecord[key.name] = 0;
                        break;
                    default:
                        nextRecord[key.name] = null;
                        break;
                }
            })
            setNewRecord(nextRecord)
        } else {
            setNewRecord(props.record)
        }
        setEnvName(props.envName)
    }, [props.envName, props.keys, props.record]);

    const renderInput = key => {
        let customOptions = null;
        switch(key) {
            case "race_type":
                customOptions = [
                    { value: 'HORSE', label: 'HORSE' },
                    { value: 'HARNESS', label: 'HARNESS' },
                    { value: 'GREYHOUND', label: 'GREYHOUND' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            accepter={CustomPicker}
                            plaintext={((props.readOnly) || (props.operation === commonCfg.OPERATIONS.UPDATE))}
                            multiPick={false}
                            data={customOptions}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "state":
                customOptions = [
                    { value: 'NSW', label: 'NSW' },
                    { value: 'QLD', label: 'QLD' },
                    { value: 'TAS', label: 'TAS' },
                    { value: 'VIC', label: 'VIC' },
                    { value: 'WA', label: 'WA' },
                    { value: 'SA', label: 'SA' },
                    { value: 'ACT', label: 'ACT' },
                    { value: 'NT', label: 'NT' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            plaintext={((props.readOnly) || (props.operation === commonCfg.OPERATIONS.UPDATE))}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "threshold_name":
                customOptions = [
                    { value: 'MBL01', label: 'MBL01' },
                    { value: 'MBL02', label: 'MBL02' },
                    { value: 'NONE', label: 'NONE' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            plaintext={props.readOnly}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            default:
                return (<></>);
        }
    }

    const addRecord = (envName) => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                formData.append(key.name, value);
            }
        });

        const apiUrl = `${props.apiUrl}/add/${envName}`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                if(data.status === 200) {
                    msg_type = 'success';
                    msg = data.msg;
                } else {
                    msg = `${split(envName, '-')[0]} - ${data.detail.msg}`
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        <b>{msg}</b>
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 30000 })
            }).finally(() => {
                props.refetchData()
            });
    }

    const updateRecord = (envName) => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                formData.append(key.name, value);
            }
        });

        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/update/${record.name}/${record.id}`, "POST", formData)))
                .then(allData => {
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = `${data.detail.msg}`
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                }).finally(() => {
                    props.refetchData()
                });
    }

    const deleteRecord = () => {
        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.name}/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                ).finally(() => {
                    props.refetchData()
                });
    }

    const operationHandler = () => {
        if (props.operation === commonCfg.OPERATIONS.CREATE) {
            envName.map((item) => addRecord(item));
        } else if (props.operation === commonCfg.OPERATIONS.UPDATE) {
            updateRecord();
        } else {
            deleteRecord();
        }
    };

    return (
        <>
            <Form>
                <FlexboxGrid justify="space-between" className="add-records-row" style={{ display: "flex", margin: "5px" }}>
                    {(props.envName.length === 0) && <FlexboxGrid.Item colspan={6} key={`add-bookmaker`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            <Form.Group controlId="envName">
                                <Form.Control
                                    name="envName"
                                    accepter={EnvPicker}
                                    value={envName}
                                    multiPick={true}
                                    exclude={["dev-winter", "qat-winter", "staging-winter", "prod-winter"]}
                                    type={['core/ALL']}
                                    label="Environment"
                                    onChange={envNameChangeHandler}
                                />
                            </Form.Group>
                        </div>
                    </FlexboxGrid.Item>}
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={key.width} key={`add-${index}`}>
                            <div className="add-records-col" style={{ display: "block" }}>
                                {renderInput(key.name)}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`add-action`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            {(props.operation === commonCfg.OPERATIONS.DELETE) && <b>Are you sure to delete ?</b>}
                            <Confirm onConfirm={operationHandler} onCancel={props.refetchData} />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );
};
export default Operation;
