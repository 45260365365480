import React, { useState, useEffect } from "react";
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { gitopsStatus } from "./config";

const Status = ({...props}) => {
	const [element, setElement] = useState(<React.Fragment/>);
	
	useEffect(() => {
		let statusElement = <SpinnerIcon pulse style={{ fontSize: props.size }} />

		switch(props.status) {
			case gitopsStatus.AMBER:
				statusElement = <InfoOutlineIcon color="#f5a623" style={{ fontSize: props.size }} />
				break;
			case gitopsStatus.GREEN:
				statusElement = <CheckOutlineIcon color="green" style={{ fontSize: props.size }} />
				break;
			case gitopsStatus.RED:
				statusElement = <CloseOutlineIcon color="red" style={{ fontSize: props.size }} />
				break;
			default:
				break;
		}
		setElement(statusElement)
	}, [props.size, props.status]);

	return (
		<>
			{element}
		</>
	);
};

export default Status;