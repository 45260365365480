export const formCfg = {
    WIDTH: 400
}

export const envType = {
    NONE: "none",
    FEEDER: "feeder",
    CORE: "core",
    WINCORE: "wincore",
    KAFKA: "kafka",
    WINTER: "winter",
    SREPLAYBOOK: "sreplaybook"
}

export const formGroupType = {
    ACCESSTYPEPICKER: "accesstypepicker",
    BIRTHDATE: "birthdate",
    BOOLEAN: "boolean",
    DATAPICKER: "datapicker",
    DATERANGE: "daterange",
    EMAIL: "email",
    ENVPICKER: "envpicker",
    EXPIRYTIMESTAMP: "expirytimestamp",
    EXTERNALREF: "externalref",
    FEEDSTEP: "feedstep",
    MOBILE: "mobile",
    NUMBER: "number",
    PREVIOUSTIMESTAMP: "previoustimestamp",
    QUERYPICKER: "querypicker",
    READFILE: "readfile",
    READONLY: "readonly",
    STATEPICKER: "statepicker",
    TEXT: "text",
    TEXTAREA: "textarea",
    TIMESTAMP: "timestamp",
    UPLOADFILE: "uploadfile",
    WITHDRAWALSTATUSPICKER: "withdrawalstatuspicker"
}

export const eventIdType = {
    RACEID: "raceid",
    MATCHID: "matchid",
    COMPETITIONID: "competitionid"
};