import React, { useEffect, useState } from "react";
import { Form, FlexboxGrid, Message, useToaster, Toggle } from 'rsuite';
import split from 'lodash/split';
import get from 'lodash/get';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import EnvPicker from "../../FormGroup/EnvPicker";
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";
import { commonCfg } from "../Common/config";
import Timestamp from "../../FormGroup/Timestamp";

const Operation = ({...props}) => {
    const toaster = useToaster();
    const [envName, setEnvName] = useState([]);
    const [newRecord, setNewRecord] = useState({});
    const [loading, setLoading] = useState(false);

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const changeHandler = (value, name) => {
        let newValue = value;
        let newObj = {...newRecord};

        if (value === null) {
            switch (name) {
                case "start_date":
                case "end_date":
                    newObj = omit(newObj, [name]);
                    break;
                default:
                    newObj[name] = "";
            }
        } else {
            newObj[name] = newValue;
        }

        setNewRecord(newObj);
    }

    useEffect(() => {
        let nextRecord = {}
        if (props.record === undefined) {
            props.keys.forEach(key => {
                nextRecord[key.name] = null;       
            })
            setNewRecord(nextRecord)
        } else {
            nextRecord = omit(props.record, ['children'].concat(filter(props.keys, ['isMetaData', true]).map(key => key.name)))
            setNewRecord(nextRecord)
        }
        setEnvName(props.envName)
    }, [props.envName, props.keys, props.record]);

    const renderInput = key => {
        switch(key.name) {
            case "top_level":
                return (
                    <Form.Group controlId={key.name}>
                        <Form.Control
                            name={key.name}
                            accepter={Toggle}
                            checked={get(newRecord, key.name, false)}
                            style={{
                                "--rs-toggle-checked-bg": "#c73f00",
                                "--rs-toggle-checked-hover-bg": "#a83800"
                            }}
                            onChange={(value, event) => changeHandler(value, key.name)}
                        />
                    </Form.Group>
                )
            case "start_date":
            case "end_date":
                return (
                    <Form.Group controlId={key.name} >
                        <Form.Control
                            name={key.name}
                            accepter={Timestamp}
                            minDate={new Date()}
                            plaintext={props.readOnly || key.isReadOnly}
                            value={get(newRecord, key.name, null)}
                            onChange={(value, event) => changeHandler(value, key.name)}
                        />
                    </Form.Group>
                )
            default:
                let value = get(newRecord, key.name, "--");
                if (value === 0) {
                    value = "0"
                }
                return (
                    <Form.Group controlId={key.name}>
                        <Form.Control
                            name={key.name}
                            plaintext={props.readOnly || key.isReadOnly}
                            value={value}
                            onChange={(value, event) => changeHandler(value, key.name)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
        }
    }

    const updateRecord = (envName) => {
        const formData = new FormData();
        let nextRecord = newRecord;
        nextRecord["id"] = props.record.children[0].id;
        formData.append("record", JSON.stringify(nextRecord));

        playFetch(`${props.apiUrl}/update/${props.record.children[0].name}/${props.record.children[0].id}`, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                    if(data.status === 200) {
                        msg_type = 'success';
                        msg = data.msg;
                    } else {
                        msg = `${split(envName, '-')[0]} - ${data.detail.msg}`
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            <b>{msg}</b>
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 30000 })
                }).finally(() => {
                    props.refetchData()
                });
    }

    const deleteRecord = () => {
        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.name}/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 10000 })
                    })
                ).finally(() => {
                    setLoading(false)
                    props.refetchData()
                });
    }

    const operationHandler = () => {
        setLoading(true)

        if (props.operation === commonCfg.OPERATIONS.UPDATE) {
            updateRecord();
        } else if (props.operation === commonCfg.OPERATIONS.DELETE) {
            deleteRecord();
        }
    };

    return (
        <>
            <Form fluid>
                <FlexboxGrid justify="space-between" className="add-records-row" style={{ display: "flex", margin: "5px" }}>
                    {(props.showSelectColumn === true) && <FlexboxGrid.Item colspan={1} key={`select-record-column`}>
                    </FlexboxGrid.Item>}
                    {(props.envName.length === 0) && <FlexboxGrid.Item colspan={6} key={`add-bookmaker`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            <Form.Group controlId="envName">
                                <Form.Control
                                    name="envName"
                                    accepter={EnvPicker}
                                    value={envName}
                                    multiPick={true}
                                    exclude={["dev-winter", "qat-winter", "staging-winter", "prod-winter"]}
                                    type={['core/ALL']}
                                    label="Environment"
                                    onChange={envNameChangeHandler}
                                />
                            </Form.Group>
                        </div>
                    </FlexboxGrid.Item>}
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={key.width} key={`add-${index}`}>
                            <div className="add-records-col" style={{ display: "block" }}>
                                {renderInput(key)}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`add-action`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            {((props.operation === commonCfg.OPERATIONS.DELETE) && (loading === false)) &&
                                <b>Are you sure to delete ?</b>}
                            <Confirm loading={loading} onConfirm={operationHandler} onCancel={props.refetchData} />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );
};
export default Operation;
