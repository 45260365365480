import { useIdleTimer } from "react-idle-timer"

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

const useIdleTimeout = ({ onIdle, onPrompt, idleTime = 20, promptTime = 10 }) => {
    const idleTimeout = 1000 * idleTime;
    const promptTimeout = 1000 * promptTime;

    const { getRemainingTime, activate } = useIdleTimer({
        timeout: idleTimeout,
        onIdle: onIdle,
        promptBeforeIdle: promptTimeout,
        onPrompt: onPrompt,
        debounce: 500
    })

    return {getRemainingTime, activate}
}

export default useIdleTimeout;