import React from "react";
import { Stack } from 'rsuite';
import GearIcon from '@rsuite/icons/Gear';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import "./records.css"

const Confirm = ({...props}) => {
    const [submitDisabled, setSubmitDisabled] = React.useState(false)

    React.useEffect(() => {
        if (props.submitDisabled !== undefined) {
            setSubmitDisabled(props.submitDisabled)
        }
    }, [props.submitDisabled]);

    const renderComponent = key => {
        if (props.loading === true) {
            return (
                <GearIcon color='black' spin style={{ fontSize: '2em' }} />
            );
        } else {
            return (
                <Stack spacing={15} justifyContent="center">
                    <div
                        onClick={((submitDisabled === false) ? () => props.onConfirm() : () => {})}
                        style={{
                            padding: "4px",
                            flex: 1,
                            opacity: ((submitDisabled === false) ? 1.0 : 0.4),
						    cursor: ((submitDisabled === false) ? "pointer" : "not-allowed"),
						    "&:hover": {
			    			    cursor: ((submitDisabled === false) ? "pointer" : "not-allowed")
				    	    }
					    }}
                    >
                        <CheckRoundIcon color='green' style={{ fontSize: '2em' }} />
                    </div>
                    <div
                        onClick={() => props.onCancel()}
                        style={{
                            flex: 1,
						    cursor: "pointer",
						    "&:hover": {
			    			    cursor: "pointer"
				    	    }
					    }}
                    >
                        <WarningRoundIcon color='red' style={{ fontSize: '2em' }} />
                    </div>
                </Stack>
            );
        }
    }

    return (
        <>
            {renderComponent()}
        </>
    )
};
export default Confirm;