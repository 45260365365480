import { commonCfg } from "../Common/config";

export const priceAdjustCfg = {
    KEYS: [
        {
            name: "race_type",
            width: 2
        },
        {
            name: "country_code",
            width: 2
        },
        {
            name: "venue_code",
            width: 2
        },
        {
            name: "meeting_type",
            width: 2
        },
        {
            name: "meeting_date",
            width: 2
        },
        {
            name: "market_type",
            width: 2
        },
        {
            name: "time_period",
            width: 2
        },
        {
            name: "adjustment",
            width: 2
        }
    ],
    ACTIONWIDTH: 2,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.DELETE
    ],
    ADJUSTMENT_RANGE: {
        MIN: -200,
        MAX: 200
    }
}