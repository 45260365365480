import { commonCfg } from "../Common/config";

export const mblthresholdCfg = {
    KEYS: [
        {
            name: "threshold_name",
            width: 7
        },
        {
            name: "race_type",
            width: 7
        },
        {
            name: "state",
            width: 7
        }
    ],
    ACTIONWIDTH: 3,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.UPDATE,
        commonCfg.OPERATIONS.DELETE
    ]
}