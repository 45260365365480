import DbAccess from '../../components/DbAccess';
import SessionTimer from '../../components/SessionTimer';
import Header from '../../components/Header';

const Database = () => {
	return (
		<>
			<SessionTimer />
            <Header />
			<div className='main'>
				<div className='area' style={{ width: "100%" }} >
					<DbAccess />
				</div>
			</div>
		</>
	);
};

export default Database;