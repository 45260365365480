import React, { useEffect, useState } from "react";
import { FlexboxGrid, ButtonToolbar, IconButton, Button, Divider, Pagination, Modal, Panel, Form, Input, SelectPicker } from 'rsuite';
import ReloadIcon from '@rsuite/icons/Reload';
import SettingIcon from '@rsuite/icons/Setting';
import Plan from "./plan";
import Create from "./create";
import { playFetch } from "../../../lib/playfetch";
import { formCfg } from "../../FormGroup/config";

const Terraform = ({...props}) => {
	const [plans, setPlans] = useState([]);
	const [loading, setLoading] = useState(true);
	const [openCreate, setOpenCreate] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [openFilter, setOpenFilter] = useState(false);
	const [branchName, setBranchName] = useState("");
	const [status, setStatus] = useState("");

	const reloadHandler = (event) => {
		event.preventDefault();
        fetchPlans();
	};

	const resetFilterHandler = (event) => {
		event.preventDefault();
		setBranchName("")
		setStatus("")
	};

	const filterHandler = (event) => {
		event.preventDefault();
		setOpenFilter(true)
	};

	const branchNameChangeHandler = (value, event) => {
        setBranchName(value);
    };

	const statusChangeHandler = (value, event) => {
        setStatus(value);
    };

	const fetchPlans = (force) => {
		setLoading(true)
		setPlans([])

		const formData = new FormData();
		formData.append("page", activePage)
		formData.append("branch", branchName)
		formData.append("status", status)
		
		playFetch(`deployments/terraform/fetch/plans`, "POST", formData)
				.then(data => {
					setPlans(data)
					setLoading(false)
				});
	};

	useEffect(() => {
		if (openFilter === false) {
			fetchPlans();
		}
		// eslint-disable-next-line
	}, [activePage, openFilter]);

    return (
		<>
			<FlexboxGrid justify="end">
				<FlexboxGrid.Item colspan={4}>
					<IconButton
						icon={<SettingIcon color="#4C4E52" />}
						circle
						size="md"
						color="cyan"
						disabled={loading}
						onClick={filterHandler}
						appearance="primary" />
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={20}>
					<div style={{ display: "flex",
								  alignItems: "center",
								  justifyContent: "right" }}>
						<ButtonToolbar>
							<Button
								color="cyan"
								size="md"
								appearance="primary"
								disabled={loading || props.readOnly}
								onClick={() => setOpenCreate(true)}
								style={{ borderRadius: "20px" }}>
									<div style={{ color: "#4C4E52" }} >
										Create a plan
									</div>
							</Button>
							<IconButton
								icon={<ReloadIcon color="#4C4E52" spin={loading} />}
								circle
								size="md"
								color="cyan"
								disabled={loading}
								onClick={reloadHandler}
								appearance="primary" />
						</ButtonToolbar>
					</div>
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
			<FlexboxGrid justify="end">
			<Pagination
				layout={['pager']}
				prev
				next
				total={10000}
				limit={25}
				size="md"
				disabled={loading}
				maxButtons={5}
				activePage={activePage}
				onChangePage={setActivePage}
			/>
			</FlexboxGrid>
			<Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
			{Object.values(plans).map((plan, index) =>
				<Plan key={`terraform-plan-${index}`} data={plan} readOnly={props.readOnly} />
			)}
			{ (openCreate === true) && <>
					<Create
						open={openCreate}
						onClose={() => setOpenCreate(false)}
					/>
				</>
			}
			{ (openFilter === true) && <>
					<Modal
                		open={openFilter}
                		onClose={() => setOpenFilter(false)}
                		backdrop="static"
            		>
						<Modal.Header>
                    		<Modal.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        		Setup the filter
                    		</Modal.Title>
                		</Modal.Header>
						<Modal.Body>
							<Panel bordered>
								<Form fluid>
									<Form.Group>
										<Form.ControlLabel>Branch</Form.ControlLabel>
										<Form.Control
											name="filter-branch"
											key="filter-branch"
											value={branchName}
											accepter={Input}
											onChange={branchNameChangeHandler}
											style={{ width: formCfg.WIDTH }} />
									</Form.Group>
									<Form.Group>
										<Form.ControlLabel>Status</Form.ControlLabel>
										<Form.Control
											name="filter-status"
											key="filter-status"
											data={['SUCCESSFUL', 'FAILED', 'PAUSED'].map(
												item => ({ label: item, value: item })
											)}
											value={status}
											accepter={SelectPicker}
											onChange={statusChangeHandler}
											style={{ width: formCfg.WIDTH }} />
									</Form.Group>
									<Form.Group>
										<ButtonToolbar style={{ display: "flex",
								  								alignItems: "center",
								  								justifyContent: "right" }}>
											<Button appearance="primary" onClick={resetFilterHandler}>Clear Filters</Button>
										</ButtonToolbar>
									</Form.Group>
								</Form>
							</Panel>
						</Modal.Body>
						<Modal.Footer>
						</Modal.Footer>
					</Modal>
				</>
			}
		</>
	);
};

export default Terraform;