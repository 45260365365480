import React, { useState, useEffect } from "react";
import { Drawer, Panel, Input, Button, Form, useToaster, Message, Toggle } from 'rsuite';
import uniq from "lodash/uniq"
import EnvPicker from "../../../FormGroup/EnvPicker";
import CustomPicker from "../../../FormGroup/CustomPicker";
import { formCfg } from "../../../FormGroup/config";
import { playFetch } from "../../../../lib/playfetch";
import { mobileGroups } from "../config";
import { toTitleCase } from "../../../../lib/utils";

const Edit = ({...props}) => {
	const toaster = useToaster();
	const [envName, setEnvName] = useState([]);
	const [branchName, setBranchName] = useState("release/10.11");
    const [appName, setAppName] = useState(["ios", "android"]);
    const [targetVer, setTargetVer] = useState("10.11");
    const [addlTargetVer, setAddlTargetVer] = useState("");
    const [live, setLive] = useState(false);
    const [commitSha, setCommitSha] = useState("");
    const [mandatory, setMandatory] = useState(false);
	const [releaseNotes, setReleaseNotes] = useState("");
    const [promoteToLive, setPromoteToLive] = useState(false);
	const [formError, setFormError] = useState({});
	const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)

	const envNameChangeHandler = (value, name) => {
        setEnvName(value);
    };

	const branchNameChangeHandler = (value, event) => {
        setBranchName(value);
    };

    const targetVerChangeHandler = (value, event) => {
        if (value !== null) {
            setTargetVer(value);
        }
    };

    const addlTargetVerChangeHandler = (value, event) => {
        if (value !== null) {
            setAddlTargetVer(value);
        }
    };

    const commitShaChangeHandler = (value, event) => {
        if (value !== null) {
            setCommitSha(value);
        }
    };

    const releaseNotesChangeHandler = (value, event) => {
        setReleaseNotes(value);
    };

	const handleFormCheck = (formError) => {
        setFormError(formError);
    };

	const submitHandler = (value, event) => {
        setLoadResult(true)

        const groups = uniq(envName.map(env => {
            return env.split("/")[0];
        }));

        groups.forEach(grp => {
            callApi(grp)
        });

        setLoadResult(false)
		props.onClose()
    };

    const callApi = (group) => {
        const formData = new FormData();

        if (group.toLowerCase() === mobileGroups.LIVE) {
            formData.append("group_name", mobileGroups.PROD)
        } else {
            formData.append("group_name", group.toLowerCase())
        }
        formData.append("branch_name", branchName)

        if (["dev", "qat"].includes(group.toLowerCase())) {
            formData.append("project_names", `super${group.toLowerCase()}`)
        } else {
            envName.forEach(env => {
                if (env.startsWith(`${group}/`)) {
                    formData.append("project_names", env.split("/").pop().split("-")[0])
                }
            });
        }

        appName.forEach(app => {
			formData.append("app_names", app)
		});

        formData.append("target_ver", targetVer)
        formData.append("mandatory", mandatory)

        if (props.type === "upload") {
            if (addlTargetVer !== "") {
                formData.append("addl_target_ver", addlTargetVer)
            }
            if (releaseNotes !== "") {
                formData.append("release_notes", releaseNotes)
            }
        } else if (props.type === "update"){
            formData.append("live", live)
            formData.append("promote_to_live", promoteToLive)
            formData.append("commit_sha", commitSha)
        }

        playFetch(`deployments/mobile/${props.type}`, "POST", formData)
            .then(data => {
                let msg_type = 'success'
                let msg = "Unknown"
                if (data.ok === false) {
                    msg_type = 'error'
                    msg = data.reason
                } else {
                    msg = (<>
                            <p>
                                {data.id_type}:&nbsp;
                                <a href={data.href} rel="noreferrer" target="_blank">{data.id}</a>
                            </p>
                        </>);
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        {msg}
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 10000 })
            });
	}

	useEffect(() => {
        setLoadResult(false)
        setSubmitDisabled(true)
		setEnvName([])
		setBranchName("release/10.11")
        setAppName(["ios", "android"])
        setTargetVer("10.11")
        setAddlTargetVer("")
        setCommitSha("")
        setReleaseNotes("")
	}, []);

	useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((envName.length > 0) &&
            (branchName.length > 0) &&
            (targetVer.length > 0) &&
            (appName.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [envName, branchName, targetVer, appName]);

	const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled));
	
	return (
		<>
			<Drawer
                open={props.open}
                onClose={props.onClose}
                size="lg"
                backdrop="static"
            >
				<Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        {`${toTitleCase(props.type)} appdater release`}
                    </Drawer.Title>
                </Drawer.Header>
				<Drawer.Body>
					<Panel bordered>
                        <Form onCheck={handleFormCheck} >
                            <Form.Group controlId="upload-env" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Select environments:</Form.ControlLabel>
                                <Form.Control
									name="upload-env"
									key="upload-env"
									value={envName}
									type={['core/ALL']}
                                    cascade={true}
                                    exclude={["bat", "dev-wincore", "qat-wincore", "perf", "staging", "hotfix", "staging-wincore", "prod-wincore"]}
									multiPick={true}
                                    isGitopsStyle={false}
                                    rawValue={true}
									accepter={EnvPicker}
									onChange={envNameChangeHandler}
                                    onClean={() => setEnvName([])}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
							<Form.Group controlId="upload-branch" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify branch:</Form.ControlLabel>
                                <Form.Control
									name="upload-branch"
									key="upload-branch"
									value={branchName}
									accepter={Input}
									onChange={branchNameChangeHandler}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
                            <Form.Group controlId="upload-apps" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Select platforms:</Form.ControlLabel>
                                <Form.Control
									name="upload-apps"
									key="upload-apps"
									value={appName}
                                    data={[{ value: 'ios', label: 'ios' }, { value: 'android', label: 'android' }]}
                                    multiPick={true}
									accepter={CustomPicker}
									onChange={(value, event) => setAppName(value)}
									style={{ width: formCfg.WIDTH }}
                                />
							</Form.Group>
                            <Form.Group controlId="upload-target" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify target version:</Form.ControlLabel>
                                <Form.Control
									name="upload-target"
									key="upload-target"
									value={targetVer}
									accepter={Input}
									onChange={targetVerChangeHandler}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
                            {(props.type === "update") && <>
                                <Form.Group controlId="update-commit-sha" >
								    <Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify commit sha:</Form.ControlLabel>
                                    <Form.Control
									    name="update-commit-sha"
									    key="update-commit-sha"
									    value={commitSha}
									    accepter={Input}
									    onChange={commitShaChangeHandler}
									    style={{ width: formCfg.WIDTH }} />
							    </Form.Group>
                                <Form.Group controlId="update-live" >
                                    <Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify live flag:</Form.ControlLabel>
                                    <Form.Control
								        name="update-live"
                                        key="update-live"
                                        value={live}
                                        accepter={Toggle}
                                        checkedChildren="True"
                                        unCheckedChildren="False"
                                        onChange={(value, event) => setLive(value)}
                                        style={{ width: formCfg.WIDTH }} />
							    </Form.Group>
                            </>}
                            {(props.type === "upload") && <>
                                <Form.Group controlId="upload-addl-target" >
								    <Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify additional target versions:</Form.ControlLabel>
                                    <Form.Control
									    name="upload-addl-target"
									    key="upload-addl-target"
									    value={addlTargetVer}
									    accepter={Input}
									    onChange={addlTargetVerChangeHandler}
									    style={{ width: formCfg.WIDTH }} />
							    </Form.Group>
                            </>}
                            <Form.Group controlId="upload-mandatory" >
								<Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify mandatory flag:</Form.ControlLabel>
                                <Form.Control
								    name="upload-mandatory"
								    key="upload-mandatory"
								    value={mandatory}
								    accepter={Toggle}
                                    checkedChildren="True"
                                    unCheckedChildren="False"
								    onChange={(value, event) => setMandatory(value)}
									style={{ width: formCfg.WIDTH }} />
							</Form.Group>
                            {(props.type === "upload") && <>
                                <Form.Group controlId="upload-releasenotes" >
								    <Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify release notes:</Form.ControlLabel>
                                    <Form.Control
									    name="upload-releasenotes"
									    key="upload-releasenotes"
									    value={releaseNotes}
									    accepter={Input}
									    onChange={releaseNotesChangeHandler}
									    style={{ width: formCfg.WIDTH }} />
							    </Form.Group>
                            </>}
                            {(props.type === "update") && <>
                                <Form.Group controlId="upload-promote" >
								    <Form.ControlLabel style={{ fontWeight: 'bold' }} >Specify promote to live flag:</Form.ControlLabel>
                                    <Form.Control
									    name="upload-promote"
									    key="upload-promote"
									    value={promoteToLive}
									    accepter={Toggle}
                                        checkedChildren="True"
                                        unCheckedChildren="False"
									    onChange={(value, event) => setPromoteToLive(value)}
									    style={{ width: formCfg.WIDTH }} />
							    </Form.Group>
                            </>}
							<Button
                                appearance="primary"
                                disabled={isSubmitDisabled}
                                loading={loadResult}
                                onClick={submitHandler}
                            >
                                {toTitleCase(props.type)}
                            </Button>
						</Form>
					</Panel>
				</Drawer.Body>
			</Drawer>
		</>
	);
};

export default Edit;
