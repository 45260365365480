import { commonCfg } from "../../Common/config";

export const featuredRaceCfg = {
    KEYS: [
        {
            name: "order",
            width: 2,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "event_id",
            width: 5,
            withBookWidth: 6,
            isReadOnly: true,
            isMetaData: false
        },
        {
            name: "venue_name",
            width: 5,
            withBookWidth: 5,
            isReadOnly: true,
            isMetaData: true
        },
        {
            name: "race_number",
            width: 2,
            withBookWidth: 2,
            isReadOnly: true,
            isMetaData: true
        },
        {
            name: "start_date",
            width: 3,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        },
        {
            name: "end_date",
            width: 3,
            withBookWidth: 2,
            isReadOnly: false,
            isMetaData: false
        }
    ],
    ACTIONWIDTH: 4,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.UPDATE,
        commonCfg.OPERATIONS.DELETE
    ]
}