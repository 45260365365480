import React from "react";
import { Drawer, Nav } from "rsuite";
import FeatureFlag from "./FeatureFlag";
import Secret from "./Secret";

const Navbar = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 20 }}>
            <Nav.Item eventKey="featureflag">Feature Flags</Nav.Item>
            {(props.readOnly !== true) && <Nav.Item eventKey="secret">Secret Version</Nav.Item>}
        </Nav>
    );
};

const EnvCfg = ({ ...props }) => {
    const [activeTab, setActiveTab] = React.useState('featureflag');

    return (
        <>
            <Drawer
                open={props.open}
                onClose={props.onClose}
                size="lg"
                backdrop="static"
            >
                <Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        {`Environment config - ${props.env}`}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <Navbar appearance="tabs" active={activeTab} onSelect={setActiveTab} readOnly={props.readOnly} />
                    {(activeTab === "featureflag") && <>
                        <FeatureFlag {...props} />
                    </>}
                    {(activeTab === "secret") && <>
                        <Secret {...props}/>
                    </>}
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default EnvCfg;
