import React from "react";
import Core from "./Core";
import Mobile from "./Mobile";
import Terraform from "./Terraform";
import { getProjectId, getUserRole } from "../Authentication";
import { projectTypes } from "../../config/app";

const Gitops = ({...props}) => {
    const [readOnly, setReadOnly] = React.useState(true);

    React.useEffect(() => {
		if (getUserRole().some(role => ["Platform", "Gitops"].includes(role))) {
			setReadOnly(false)
		}
	}, []);

	return (
		<>
            {(props.type === 'core') && <Core readOnly={readOnly} /> }
            {((props.type === 'mobile') && (getProjectId() === projectTypes.CORE)) && <Mobile readOnly={readOnly} /> }
			{((props.type === 'terraform') && (getProjectId() === projectTypes.CORE)) && <Terraform readOnly={readOnly} /> }
		</>
	);
};

export default Gitops;