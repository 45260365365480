import axios from "axios";
import { logout, getProjectId } from "../components/Authentication";
import { appCfg } from "../config/app"

export const playFetch = async (
                url,
                requestMethod = "GET",
                requestBody = null) => {
    const apiUrl = appCfg.BASE_API_URL + url
    const requestOptions = {
        method: requestMethod,
        withCredentials: true,
        data: requestBody,
        headers: {
            'Request-Type': getProjectId()
        }
    };

    let response;
    await axios(apiUrl, requestOptions)
            .then(data => { response = data })
            .catch(error => { response = error.response; })

    if(response.status === 401) {
        logout()
    }
    return response.data
};
