import React from "react";
import { Modal, Panel, Button, Message, useToaster, Stack, Divider } from 'rsuite';
import CodeEditor from '@uiw/react-textarea-code-editor';
import rehypePrism from "rehype-prism-plus";
import GearIcon from '@rsuite/icons/Gear';
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";

const JobViewer = ({...props}) => {
    const toaster = useToaster();
    const [openJobViewer, setOpenJobViewer] = React.useState(false);
    const [approve, setApprove] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const operationHandler = () => {
        setOpenJobViewer(false);
        setLoading(true)

        let apiUrl = `generic/jobs/approval/delete/${props.approval_id}`
        if (approve === true) {
            apiUrl = `generic/jobs/approval/approve/${props.approval_id}`
        }

        playFetch(apiUrl)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                    if(data.status === 200) {
                        msg_type = 'success';
                        msg = data.msg;
                    } else {
                        msg = `${data.detail.msg}`
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            <b>{msg}</b>
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 30000 })
                }).finally(() => {
                    setLoading(false)
                    props.refetch()
                });
    }

    const closeHandler = () => {
        setOpenJobViewer(false)
    }

    const approveHandler = () => {
        setApprove(true)
        setConfirm(true)
    }

    const discardHandler = () => {
        setApprove(false)
        setConfirm(true)
    }

    return (
        <>
            {(loading === true) && <GearIcon color='#000000' spin style={{ fontSize: '2em' }} />}
            {(loading === false) && <Button
                color="orange"
                size="xs"
                appearance="ghost"
                onClick={() => setOpenJobViewer(!openJobViewer)}
                style={{ borderRadius: "20px", borderWidth: "2px", borderColor: "black" }}>
                <div style={{ color: "#4C4E52" }} >
                    <b>&nbsp;View&nbsp;</b>
                </div>
            </Button>}
            <Modal
                backdrop={true}
                keyboard={false}
                open={openJobViewer}
                onClose={closeHandler}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        {`Job - ${props.approval_id}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel shaded bordered>
                        {(confirm === false) && <Stack spacing={20} justifyContent="flex-end">
                            <Button
                                color="red"
                                size="sm"
                                appearance="primary"
                                onClick={approveHandler}
                                style={{ borderRadius: "20px", borderWidth: "2px", borderColor: "black" }}>
                                <div style={{ color: "white" }} >
                                    <b>&nbsp;&nbsp;&nbsp;Approve&nbsp;&nbsp;&nbsp;</b>
                                </div>
                            </Button>
                            <Button
                                color="green"
                                size="sm"
                                appearance="primary"
                                onClick={discardHandler}
                                style={{ borderRadius: "20px", borderWidth: "2px", borderColor: "black" }}>
                                <div style={{ color: "white" }} >
                                    <b>&nbsp;&nbsp;&nbsp;Discard&nbsp;&nbsp;&nbsp;</b>
                                </div>
                            </Button>
                        </Stack>}
                        {(confirm === true) && <div style={{ display: "flex", justifyContent: "flex-end " }}>
                            {(approve === true) && <b style={{ fontSize: "20px" }}>
                                Are you sure to approve the request ?&nbsp;&nbsp;
                            </b>}
                            {(approve === false) && <b style={{ fontSize: "20px" }}>
                                Are you sure to discard the request ?&nbsp;&nbsp;
                            </b>}
                            <Confirm loading={loading} onConfirm={operationHandler} onCancel={() => setConfirm(false)} />
                        </div>}
                        <Divider />
                        <CodeEditor
                            value={props.query}
                            language="sql"
                            data-color-mode="light"
                            readOnly={true}
                            padding={15}
                            minHeight={300}
                            rehypePlugins={[
                                [rehypePrism, { ignoreMissing: true }]
                            ]}
                            style={{
                                fontSize: 18,
                                backgroundColor: "#f9faf7",
                                fontFamily: "'Source Code Pro', SFMono-Regular, Menlo, Monaco, Consolas, 'Courier New', monospace, 'Liberation Mono'",
                                border: "2px black solid",
                                width: "700px"
                            }}
                        />
                    </Panel>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default JobViewer;
