import React from "react";
import { DatePicker } from "rsuite";
import { isBefore, isAfter } from "date-fns";
import { formCfg } from "../config";

const Timestamp = ({ ...props }) => {
    const dateChangeHandler = (value) => {
        if (value === null) {
            props.onChange(null, props.name);
        } else {
            props.onChange(value.toISOString(), props.name);
        }
    };

    const dateCleanHandler = () => {
        props.onChange(null, props.name);
    };

    const checkDate = (date) => {
        let disableDate = true
        if (props.minDate !== undefined) {
            disableDate = disableDate && (isBefore(date, props.minDate))
        }
        if (props.maxDate !== undefined) {
            disableDate = disableDate && (isAfter(date, props.maxDate))
        }
        return disableDate
    };

    return (
        <>
            {((props.plaintext === true) && (props.value === null)) ?
                "--" :
                <DatePicker
                    format="yyyy-MM-dd HH:mm:ss"
                    plaintext={props.plaintext}
                    defaultValue={(props.minDate !== undefined) ? props.minDate : ((props.maxDate !== undefined) ? props.maxDate : (new Date()))}
                    shouldDisableDate={date => checkDate(date)}
                    value={((props.value === null) || (props.value === "")) ? null : new Date(props.value)}
                    ranges={
                        [
                            {
                                label: 'Now',
                                value: new Date()
                            }
                        ]
                    }
                    onChange={dateChangeHandler}
                    onClean={dateCleanHandler}
                    style={{ width: formCfg.WIDTH }}
                />
            }
        </>
    );
};

export default Timestamp;
