import React from "react";
import { GetCountries, GetState } from "react-country-state-city";
import { find } from "lodash";
import { SelectPicker } from "rsuite";
import { formCfg } from "../config";
import { getProjectId } from "../../Authentication";
import { projectTypes } from "../../../config/app";

const StatePicker = ({...props}) => {
    const [stateOptions, setStateOptions] = React.useState([])

    React.useEffect(() => {
        GetCountries().then((countries) => {
            GetState(find(countries, ['iso3', (getProjectId() === projectTypes.CASINO) ? "USA" : "AUS"]).id)
                .then((states) => {
                    const stateData = states.map(state => {
                        return { value: state.name, label: state.name }
                    })
                    setStateOptions(stateData)
                })
        });
    }, [props.id]);

    const compare = (a, b) => {
        let nameA = a.toUpperCase();
        let nameB = b.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
    };

    const stateChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const stateCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };

    return (
        <SelectPicker
            data={stateOptions}
            onChange={stateChangeHandler}
            onClean={stateCleanHandler}
            sort={isGroup => {
                if (isGroup) {
                  return (a, b) => {
                    return compare(a.groupTitle, b.groupTitle);
                  };
                }
                return (a, b) => {
                  return compare(a.label, b.label);
                };
            }}
            style={{ width: formCfg.WIDTH }} />
    );
};

export default StatePicker;
