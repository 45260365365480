import Header from '../../components/Header';
import SessionTimer from '../../components/SessionTimer';
import Gitops from '../../components/Gitops';

const Deployment = ({...props}) => {
	return (
		<>
			<SessionTimer />
			<Header />
			<div className='main'>
				<div className='area' style={{ width: "100%" }} >
					<Gitops type={props.type} />
				</div>
			</div>
		</>
	);
};

export default Deployment;