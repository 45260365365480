import find from 'lodash/find';
import pick from 'lodash/pick';
import uniqueId from 'lodash/uniqueId';
import { playFetch } from "../../../../lib/playfetch";

export const fetch = async (keys, requestData, clubIdenticalRecords=true) => {
    let nextData = [];

    await Promise.all(requestData.map((request) => playFetch(request.apiUrl)))
        .then(allResponse => allResponse?.map(response =>
            response?.data?.forEach(item => {
                let record = item;
                let pushRecord = true;
                if((undefined === find(nextData, pick(record, keys))) || (clubIdenticalRecords === false)) {
                    record["children"] = []
                } else {
                    record = find(nextData, pick(record, keys))
                    pushRecord = false;
                }
                                            
                let env = {}
                env["name"] = response.env
                env["id"] = item.id
        
                if(undefined === find(record["children"], env)) {
                    record["children"].push(env)
                }
                record.id = uniqueId()
                if(pushRecord === true) {
                    nextData.push(record)
                }
            })
        )
    );

    return nextData;
};