import React from "react";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay } from "date-fns";
import { formCfg } from "../config";

const DateRange = ({ ...props }) => {
    const dateChangeHandler = (value) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            const startDate = startOfDay(value[0]).toISOString()
            const endDate = endOfDay(value[1]).toISOString()
            props.onChange([startDate, endDate], props.name);
        }
    };

    return (
        <DateRangePicker
            isoWeek
            format="dd/MM/yyyy"
            onChange={dateChangeHandler}
            style={{ width: formCfg.WIDTH }} />
    );
};

export default DateRange;
