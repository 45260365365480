import React from "react";
import { filter } from "lodash";
import { recordsCfg } from "../config.js";
import QuickLinks from "./QuickLinks";
import FeaturedRace from "./FeaturedRace";
import FeaturedMatch from "./FeaturedMatch";
import { getEnvironments } from "../../Authentication";

const QuickNav = ({...props}) => {
    const [excludedEnvs, setExcludedEnvs] = React.useState(undefined);

    React.useEffect(() => {
        async function fetchAuthEnvironments() {
            const data = await getEnvironments();
            setExcludedEnvs(filter(data.core, ['is_scaled_down', true]).map(i => i.label))
        }
        fetchAuthEnvironments()
    }, [props.id])

    return (
        <>
            {(excludedEnvs !== undefined) && <>
                {(props.id === recordsCfg.TYPE.QUICKLINKS) && <QuickLinks {...props} excludedEnvs={excludedEnvs} />}
                {(props.id === recordsCfg.TYPE.FEATUREDRACE) && <FeaturedRace {...props} excludedEnvs={excludedEnvs} />}
                {(props.id === recordsCfg.TYPE.FEATUREDMATCH) && <FeaturedMatch {...props} excludedEnvs={excludedEnvs} />}
            </>}
        </>
    );
};
export default QuickNav;