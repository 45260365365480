import React from "react";
import { RadioGroup, Radio } from "rsuite";
import { eventIdType } from "../config";

const EventIdType = ({...props}) => {
    const eventIdTypeChangeHandler = (value, event) => {
        props.onChange(value, props.name);
    };

    return (
        <>
            <RadioGroup
                name="eventIdType"
                inline
                value={props.value}
                onChange={eventIdTypeChangeHandler}
            >
                <Radio value={eventIdType.RACEID}>{eventIdType.RACEID}</Radio>
                <Radio value={eventIdType.MATCHID}>{eventIdType.MATCHID}</Radio>
                <Radio value={eventIdType.COMPETITIONID}>{eventIdType.COMPETITIONID}</Radio>
            </RadioGroup>
        </>
    );
};

export default EventIdType;
