import React from 'react';
import { playbooks } from "../../config/playbooks";
import Execmode from './execmode';
import Autoplay from './autoplay';
import Manual from './manual';

const Playbook = ({ playbookId, setHelpText }) => {
    let element = <React.Fragment />

    if(('autoplay' in playbooks[playbookId]) &&
        ('steps' in playbooks[playbookId])) {
        element = <Execmode props={{ playbookId: playbookId }} />
    } else if('autoplay' in playbooks[playbookId]) {
        element = <Autoplay props={{ playbookId: playbookId }} />
    } else {
        element = <Manual props={{ playbookId: playbookId, setHelpText: setHelpText }} />
    }

    return (
        <>{ element }</>
    );
};

export default Playbook;