import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { Modal, Button } from "rsuite";
import useIdleTimeout from '../../hooks/useIdleTimeout';
import { isAuthenticated, logout } from "../Authentication";
import { appCfg } from "../../config/app";

const SessionTimer = () => {
	const navigate = useNavigate();
    const [remainingTime, setRemainingTime] = useState(appCfg.IDLE_TIMEOUT_IN_MINUTES * 60)
    const [modalState, setModalState] = useState(false)
    const [sessionAlive, setSessionAlive] = useState(true)

	const handleLogout = async () => {
		await logout()
		navigate("/", { replace: true })
	}

	const handleIdlePrompt = async () => {
		setModalState(true)
	}

    const handleExtend = async () => {
		setModalState(false)
        activate()
	}

	const {getRemainingTime, activate} = useIdleTimeout({
								            onIdle: handleLogout,
								            onPrompt: handleIdlePrompt,
								            promptTime: 25,
								            idleTime: (appCfg.IDLE_TIMEOUT_IN_MINUTES * 60) })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(Math.ceil(getRemainingTime() / 1000))
            if(!isAuthenticated()) {
                setSessionAlive(false)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    if (sessionAlive === false) {
        return <Navigate to="/" replace />;
    } else {
	    return (
            <Modal open={modalState} >
                <Modal.Header>
                    <Modal.Title>Are you still here?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Logging out in {remainingTime} seconds.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleExtend} appearance="primary">
                        I'm still here
                    </Button>
                    <Button onClick={handleLogout} appearance="subtle">
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
	    );
    }
};

export default SessionTimer;
