import React from "react";
import { Panel, FlexboxGrid, ButtonToolbar, IconButton } from "rsuite";
import MobileIcon from '@rsuite/icons/Mobile';
import DeviceCfg from "./DeviceCfg";
import App from "./app";
import { isReadOnlyEnv } from "../../../lib/gitops";
import { getEnvironments } from "../../Authentication";

const Env = ({...props}) => {
    const [name, setName] = React.useState("");
    const [openDeviceCfg, setOpenDeviceCfg] = React.useState(false);
    const [isReadOnly, setIsReadOnly] = React.useState(true);

    const deviceCfgHandler = (event) => {
		event.preventDefault();
        setOpenDeviceCfg(true);
	};

    React.useEffect(() => {
        async function markEnvReadOnly() {
            switch(props.name) {
                case "dev":
                case "qat":
                    setName(`super${props.name}`);
                    break;
                default:
                    setName(props.name);
                    break;
            }
            if (props.readOnly === true) {
                setIsReadOnly(true)
            } else {
                const environments = getEnvironments();
                setIsReadOnly(isReadOnlyEnv(props.name, environments?.core))
            }
        }
        markEnvReadOnly()
    }, [props.name, props.readOnly])

    const header = <FlexboxGrid justify="start" align="middle">
						<FlexboxGrid.Item colspan={16}>
							<div style={{ borderBottom: "1px solid gray",
										  borderRadius: "16px" }}
							>
								&nbsp;&nbsp;{name}
							</div>
						</FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
							<FlexboxGrid justify="end" align="middle">
								<FlexboxGrid.Item colspan={20}>
									<div style={{ display: "flex",
										  		  alignItems: "center",
										  		  justifyContent: "right" }}>
										<ButtonToolbar>
                                            {(props.name !== "dev") && <IconButton
												icon={<MobileIcon color="#4C4E52" />}
												circle
												size="xs"
												color="cyan"
												appearance="primary"
												onClick={deviceCfgHandler}
											/>}
    									</ButtonToolbar>
									</div>
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</FlexboxGrid.Item>
					</FlexboxGrid>

    return (
		<>
            <Panel header={header} shaded style={{ backgroundColor: "white", marginBottom:"20px" }}>
                {(props.data.map((app, index) =>
                    <App
                        name={app.type}
                        key={`app-${index}`}
                        readOnly={isReadOnly}
                        data={app.manifest}
                        marginBottom={5}
                    />
                ))}
            </Panel>
            { (openDeviceCfg === true) && <>
				<DeviceCfg
					open={openDeviceCfg}
					onClose={() => setOpenDeviceCfg(false)}
					group={props.group}
					env={props.name}
                    name={name}
                    readOnly={isReadOnly}
				/>
			  </>
			}
		</>
	);
};

export default Env;