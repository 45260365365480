import React, { useState, useEffect } from 'react';
import { Form, Stack, useToaster, Message } from 'rsuite';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import App from './app'
import Modules from './modules';
import { playFetch } from '../../../../lib/playfetch'

const Edit = ({...props}) => {
    const toaster = useToaster();
    const [sha, setSha] = useState('')
    const [moduleSha, setModuleSha] = useState([])
    const [showModules, setShowModules] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)

    const editHandler = () => {
        const formData = new FormData();
        formData.append("group_name", props.group)
        formData.append("env_name", props.env)

        let isChanged = false
        if ((props.name === "core-backend") &&
            (showModules === true)) {
            for (let idx = 0; idx < props.value.modules.length; idx++) {
                if (moduleSha[idx] !== props.value.modules[idx].sha) {
                    isChanged = true
                    formData.append("values", JSON.stringify({"app": props.value.modules[idx].name, "sha": moduleSha[idx]}))
                }
            }
        } else if (sha !== props.value.sha) {
            isChanged = true
            formData.append("values", JSON.stringify({"app": props.name, "sha": sha}))
        }

        /* Nothing changed */
        if (isChanged === false) {
            return
        }

		playFetch(`deployments/core/sha/edit`, "POST", formData)
			.then(data => {
                data.status.forEach(element => {
                    let msg_type = 'success'
                    let msg = "Unknown"
                    if (element.status >= 400) {
                        msg_type = 'error'
                        msg = element.reason
                    } else {
                        msg = (<>
                                <p>
                                    {element.id_type}:&nbsp;
                                    <a href={element.href} rel="noreferrer" target="_blank">{element.id}</a>
                                </p>
                            </>);
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            {msg}
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 10000 })
                });
			});
	};

    const validateHandler = (event) => {
        event.preventDefault();
        setShowConfirmation(true)
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setShowConfirmation(false)
        props.onClose()
        editHandler()
    };

    useEffect(() => {
        setSha('')
        if (props.name === "core-backend") {
            let newSha = []
            for (let idx = 0; idx < props.value.modules.length; idx++) {
                newSha[idx] = props.value.modules[idx].sha
            }
            setModuleSha(newSha)
        }
    }, [props.name, props.value.modules]);

    return (
        <Form>
            {(showConfirmation === true) ?
                <div style={{ fontStyle: "italic", fontWeight: 'bolder' }}>
                    {`Are you sure to redeploy ${props.name} for ${props.group}/${props.env} ?`}
                </div> :
            <>
            {(showModules === true) ? <>
                <Modules moduleSha={moduleSha} modules={props.value.modules} onChange={(value) => setModuleSha(value)} />
            </> : <>
                <App name={props.name} sha={sha} onChange={(value) => setSha(value)} />
            </> }
            </> }
            <Stack spacing={10} direction={(showConfirmation === false) ? "row" : "row-reverse"} >
                <div style={{ flex: 1 }}>
                    <></>
                </div>
                <div onClick={(showConfirmation === false) ? validateHandler : submitHandler} style={{
                        flex: 1,
						cursor: "pointer",
						"&:hover": {
							cursor: "pointer"
						}
					}}>
                        <CheckOutlineIcon color='green' />
                </div>
                <div onClick={() => props.onClose()} style={{
                        flex: 1,
						cursor: "pointer",
						"&:hover": {
							cursor: "pointer"
						}
					}}>
                        <CloseOutlineIcon color='red' />
                </div>
                {((props.name === "core-backend") && (showConfirmation === false)) ?
                    <div onClick={() => setShowModules(!showModules)} style={{
                        flex: 1,
						cursor: "pointer",
						"&:hover": {
							cursor: "pointer"
						}
					}}>
                        {(showModules === true) ? <CollaspedOutlineIcon color='blue' /> : <ExpandOutlineIcon color='blue' />}
                </div> : <></> }
            </Stack>
        </Form>
    );
};

export default Edit;
