import React from 'react';
import { Schema, Form } from 'rsuite';

const App = ({...props}) => {
    const rule = Schema.Types.StringType()
                    .isRequired('commit sha is required.')
                    .pattern(/^\w{7}$/,'Please enter a valid commit sha')

    return (
        <>
                <Form.ControlLabel
                    key="lbl-commit-sha"
                    style={{ fontStyle: "italic", fontWeight: 'normal' }} >
                        commit sha:
                </Form.ControlLabel>
                <Form.Control
                    name="commit-sha"
                    value={props.sha}
                    key="cntrl-commit-sha"
                    onChange={(value, event) => props.onChange(value)}
                    rule={rule}
                    errorPlacement='topEnd'
                    style={{
                        width: "100px",
                        height: "20px",
                        borderRadius: "30px",
                        fontStyle: "italic",
                        fontWeight: 'normal' }} />
        </>
    );
};

export default App;