import { commonCfg } from "../Common/config";

export const apiRolesCfg = {
    KEYS: [
        {
            name: "name",
            width: 6
        },
        {
            name: "remarks",
            width: 10
        }
    ],
    ACTIONWIDTH: 8,
    OPERATIONS: [
        commonCfg.OPERATIONS.CREATE,
        commonCfg.OPERATIONS.UPDATE,
        commonCfg.OPERATIONS.DELETE
    ]
}