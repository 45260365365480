import React, { useState } from 'react';
import { SelectPicker } from 'rsuite';
import Autoplay from './autoplay';
import Manual from './manual';

const execModeData = ['Autoplay', 'Manual'].map(
    item => ({ label: item, value: item })
);

const Execmode = ({ props }) => {
    const [execMode, setExecMode] = useState(null)

    const execModeChangeHandler = (value, event) => {
        setExecMode(value)
    }

    const execModeCleanHandler = (event) => {
        setExecMode(null)
    }

    let picker = <div style={{ marginTop: 10 }}>
                    <SelectPicker
                        label="Exec Mode"
                        block
                        onChange={execModeChangeHandler}
                        onClean={execModeCleanHandler}
                        data={execModeData} />
                 </div>

    let element = <React.Fragment />

    if(execMode === 'Autoplay') {
        element = <Autoplay props={{ playbookId: props.playbookId }} />
    }
    if (execMode === 'Manual') {
        element = <Manual props={{ playbookId: props.playbookId }} />
    }

    return (
        <React.Fragment>
            {picker}
            {element}
        </React.Fragment>
    ); 
};

export default Execmode;
