import React, { useState } from "react";
import { Uploader } from "rsuite";
import { formCfg } from "../config";

const ReadFile = ({...props}) => {
    const [uploadedFile, setUploadedFile] = useState([]);

    const changeHandler = (fileList) => {
        /* Restricting single file upload */
        if (fileList.length > 0) {
            const newFileList = [fileList[fileList.length - 1]]
            setUploadedFile(newFileList)
            const reader = new FileReader();
            reader.readAsText(newFileList[0].blobFile, "UTF-8");
            reader.onloadend = () => {
                props.onChange(reader.result, props.name);
            };
        }
    }

    const removeHandler = (file) => {
        /* Restricting single file upload */
        setUploadedFile([])
        props.onChange('', props.name)
    }

    let element = <div
                    style={{ height: 200,
                    width: formCfg.WIDTH,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center' }}
                  >
                        <span>Click or Drag file to this area to upload</span>
                  </div>

    return (
        <Uploader
            draggable
            autoUpload={false}
            action='/fake'
            multiple={false}
            fileList={uploadedFile}
            listType='picture-text'
            accept={props.type}
            name={props.name}
            onChange={changeHandler}
            onRemove={removeHandler}
            style={{ width: formCfg.WIDTH }}
        >
            {element}      
        </Uploader>
    );
};

export default ReadFile;