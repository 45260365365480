import React from "react";
import { SelectPicker, CheckPicker } from "rsuite";
import { formCfg } from "../config";

const CustomPicker = ({...props}) => {
    const customChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange(value, props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const customCleanHandler = (value, event) => {
        props.onChange(null, props.name);
    };

    return (
        <>
            {(props.multiPick === false) &&
            <SelectPicker
                data={props.data}
                value={props.value}
                disabled={props.disabled}
                plaintext={props.plaintext}
                cleanable={(props.cleanable !== undefined) ? props.cleanable : true}
                onChange={customChangeHandler}
                onClean={customCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
            {(props.multiPick === true) &&
            <CheckPicker
                data={props.data}
                value={props.value}
                disabled={props.disabled}
                plaintext={props.plaintext}
                cleanable={(props.cleanable !== undefined) ? props.cleanable : true}
                onChange={customChangeHandler}
                onClean={customCleanHandler}
                style={{ width: formCfg.WIDTH }} />
            }
        </>
    );
};

export default CustomPicker;
