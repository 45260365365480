import React, { useEffect, useState } from "react";
import { Form, Schema, FlexboxGrid, Message, useToaster} from 'rsuite';
import get from 'lodash/get';
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";
import { commonCfg } from "../Common/config";

const Operation = ({...props}) => {
    const toaster = useToaster();
    const [newRecord, setNewRecord] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [formError, setFormError] = useState({});

    const changeHandler = (value, name) => {
        let newValue = value;
        let newObj = {}

        newObj[name] = newValue;
        setNewRecord({
            ...newRecord,
            ...newObj
        });
    }

    useEffect(() => {
        const nextRecord = {}
        if (props.record === undefined) {
            props.keys.forEach(key => {
                switch(key.name) {
                    default:
                        nextRecord[key.name] = "";
                        break;
                }
            })
            setNewRecord(nextRecord)
        } else {
            setNewRecord(props.record)
        }
    }, [props.keys, props.record]);

    useEffect(() => {
        let isSubmitDisabled = false
        for (const value of Object.values(newRecord)) {
            if (value?.length === 0) {
                isSubmitDisabled = true
            }
        }
        if (isSubmitDisabled === false) {
            isSubmitDisabled = (Object.keys(formError).length > 0)
        }
        setSubmitDisabled(isSubmitDisabled)
    }, [newRecord, formError]);

    const renderInput = key => {
        const rule = Schema.Types.StringType().isRequired('This field is required.')
                
        switch(key) {
            case "name":
                return (
                    <Form.Control
                        name={key}
                        plaintext={(props.operation === commonCfg.OPERATIONS.CREATE) ? false : true}
                        value={get(newRecord, key, "")}
                        rule={rule}
                        errorPlacement='bottomStart'
                        onChange={(value, event) => changeHandler(value, key)}
                        style={{ width: "100%" }}
                    />
                )
            default:
                return (
                    <Form.Control
                        name={key}
                        plaintext={props.readOnly}
                        value={get(newRecord, key, "")}
                        rule={rule}
                        errorPlacement='bottomStart'
                        onChange={(value, event) => changeHandler(value, key)}
                        style={{ width: "100%" }}
                    />
                )
        }
    }

    const addRecord = () => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                formData.append(key.name, value);
            }
        });

        const apiUrl = `${props.apiUrl}/add`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                if(data.status === 200) {
                    msg_type = 'success';
                    msg = data.msg;
                } else {
                    msg = `${data.detail.msg}`
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        <b>{msg}</b>
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 30000 })
            }).finally(() => {
                setLoading(false)
                props.refetchData()
            });
    }

    const updateRecord = (envName) => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                formData.append(key.name, value);
            }
        });

        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/update/${record.id}`, "POST", formData)))
                .then(allData => {
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = `${data.detail.msg}`
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                }).finally(() => {
                    setLoading(false)
                    props.refetchData()
                });
    }

    const deleteRecord = () => {
        Promise.all(props.record.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 30000 })
                    })
                ).finally(() => {
                    setLoading(false)
                    props.refetchData()
                });
    }

    const operationHandler = () => {
        setLoading(true)
        if (props.operation === commonCfg.OPERATIONS.CREATE) {
            addRecord();
        } else if (props.operation === commonCfg.OPERATIONS.UPDATE) {
            updateRecord();
        } else {
            deleteRecord();
        }
    };

    const errorHandler = (formError) => {
        setFormError(formError)
    };

    return (
        <>
            <Form onCheck={errorHandler}>
                <FlexboxGrid justify="space-between" className="add-records-row" style={{ display: "flex", margin: "5px" }}>
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={key.width} key={`add-${index}`}>
                            <div className="add-records-col" style={{ display: "block" }}>
                                {renderInput(key.name)}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`add-action`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            {(props.operation === commonCfg.OPERATIONS.DELETE) && <b>Are you sure to delete ?</b>}
                            <Confirm submitDisabled={submitDisabled} loading={loading} onConfirm={operationHandler} onCancel={props.refetchData} />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );
};
export default Operation;
