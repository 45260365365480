import React, { useState, useEffect } from "react";
import { Message, useToaster } from 'rsuite';
import omit from 'lodash/omit';
import Confirm from "../../Common/confirm";
import { playFetch } from "../../../../lib/playfetch";

const Apply = ({...props}) => {
    const toaster = useToaster();
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        async function autoApply() {
            await operationHandler();
        }
        autoApply();
    });
    
    const applyRecord = async (record) => {
        const formData = new FormData();
        formData.append("record", JSON.stringify(omit(record, ["id", "children", "venue_name", "race_number", "match_name", "competition_name"])));
        await Promise.all(record.children.map(env =>
            playFetch(`${props.apiUrl}/apply/${env.name.split('-')[0]}`, "POST", formData)))
                .then(allData =>
                    allData.forEach(data => {
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 10000 })
                    })
                ).finally(() => {
                    props.applyRecord(props.record.id)
                });
        return
    }

    const operationHandler = async () => {
        if (loading === true) {
            return;
        }
        setLoading(true)
        await applyRecord(props.record)
        setLoading(false)
    };

    const cancelHandler = async () => {
        await props.cancelHandler(props.record)
        props.refetchData()
    };

    return (
        <>
            <div className="add-records-col" style={{ display: "block" }}>
                {(loading === false) && <b>Are you sure to apply ?</b>}
                <Confirm loading={loading} onConfirm={operationHandler} onCancel={cancelHandler} />
            </div>
        </>
    );
};
export default Apply;
