import React, { useState, useEffect } from "react";
import { TagInput,Panel, Form, Button, useToaster, Message, Divider } from "rsuite";
import { formCfg } from "../../../../../FormGroup/config";
import EnvPicker from "../../../../../FormGroup/EnvPicker";
import { playFetch } from "../../../../../../lib/playfetch";

const Secret = ({ ...props }) => {
    const toaster = useToaster();
    const [secretValue, setSecretValue] = React.useState("");
    const [toEnvs, setToEnvs] = useState([]);
    const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)
    
    const promoteEnvHandler = (value, event) => {
        setToEnvs(value)
    }

    const secretValueChangeHandler = (value) => {
        setSecretValue(value);
    };

    const promoteSubmitHandler = (value, event) => {
        setLoadResult(true)
        const formData = new FormData();

        /* Append promote_from_env */
        formData.append("promote_from_env", JSON.stringify({"group": props.group, "env": props.env}))

        /* Append promote_to_envs */
        for (let idx = 0; idx < toEnvs.length; idx++) {
            if (toEnvs[idx].includes("/")) {
                formData.append("promote_to_envs", JSON.stringify({"group": toEnvs[idx].split("/").shift(), "env": toEnvs[idx].split("/").pop()}))
            } else {
                for (let grp_idx = 0; grp_idx < props.rawData.groups.length; grp_idx++) {
                    for (let env_idx = 0; env_idx < props.rawData.groups[grp_idx].environments.length; env_idx++) {
                        if (props.rawData.groups[grp_idx].environments[env_idx].grp_name === toEnvs[idx]) {
                            if (props.rawData.groups[grp_idx].environments[env_idx].name !== props.env) {
                                formData.append("promote_to_envs", JSON.stringify({"group": toEnvs[idx], "env": props.rawData.groups[grp_idx].environments[env_idx].name}))
                            }
                        }
                    }
                }
            }
        }
          /* Append gcp_secrets */
          for (let idx = 0; idx < secretValue.length; idx++) {
            formData.append("gcp_secrets",secretValue[idx])
        }

        playFetch(`generic/secrets/promote`, "POST", formData)
            .then(data => {
                data.forEach(element => {
                    let msg_type = 'success'
                    let msg = element.msg
                    if (element.status >= 400) {
                        msg_type = 'error'
                        msg = `${element.reason} - ${element.msg}`
                    }
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            {msg}
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 20000 })
                });
                setLoadResult(false)
                props.onClose()
            });
    }
    
    useEffect(() => {
        setLoadResult(false)
        setSubmitDisabled(true)
        setSecretValue("")
        
	}, [props.env, props.group, props.rawData]);

    useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((toEnvs.length > 0) &&
            (secretValue.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [toEnvs,secretValue]);

    return (
        <>
            <Panel
                bordered
            >
                {<Message
                    showIcon
                    type="info"
                    style={{
                        "--rs-message-info-icon": "#0000FF",
                        "--rs-message-info-text": "#0000FF",
                        "--rs-message-info-bg": "White",
                        marginTop: "5px"
                    }}
                >
                    <b>
                        {'Please be aware that by default all previous versions of \
                                newly modified secret will be disabled.'}
                    </b>
                </Message>}
                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />                 
                <Form>
                    <Form.Group controlId="promote-env" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Promote to environments:</Form.ControlLabel>
                        <Form.Control
							name="promote-env"
							key="promote-env"
							value={toEnvs}
							type={['core/ALL', 'wincore/ALL']}
                            cascade={true}
							multiPick={true}
                            rawValue={true}
                            isGitopsStyle={true}
							accepter={EnvPicker}
							onChange={promoteEnvHandler}
                            onClean={promoteEnvHandler}
							style={{ width: formCfg.WIDTH }}
                        />
                    </Form.Group>
                    <Form.Group controlId="secretValue" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Secret Name(s):</Form.ControlLabel>
                        <Form.Control
                            name="secretValue"
                            trigger={['Enter','Space','Comma']}
                            value={secretValue}
                            accepter={TagInput}
                            onChange={secretValueChangeHandler}
                            placeholder="Enter GCP secret(s). Enter,Space or Comma seperated."
                            style={{ width: formCfg.WIDTH }} />
                    </Form.Group>
                    <Button
                        appearance="primary"
                        disabled={submitDisabled}
                        loading={loadResult}
                        onClick={promoteSubmitHandler}
                    >
                        Promote
                    </Button>
                </Form>
            </Panel>
        </>
    );
};

export default Secret;
