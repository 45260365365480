import React from "react";
import Display from "../Common/display";
import { apiRolesCfg } from "./config";
import { fetch } from "../Common/lib/fetch";
import Operation from "./operation";
import { commonCfg } from "../Common/config";

const ApiRoles = ({...props}) => {
    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch`}
        })

        const data = await fetch(
                                apiRolesCfg.KEYS.map(key => key.name),
                                requestData,
                                false);
        return data;
    }

    const renderOperation = (refetch, record, readOnly, operation) => {
        return <Operation
                    {...props}
                    keys={apiRolesCfg.KEYS}
                    actionWidth={apiRolesCfg.ACTIONWIDTH}
                    refetchData={refetch}
                    readOnly={readOnly}
                    operation={operation}
                    record={record}
                />
    }

    const renderCreate = (refetch) => {
        return renderOperation(refetch, undefined, false, commonCfg.OPERATIONS.CREATE)
    }

    const renderDelete = (refetch, record) => {
        return renderOperation(refetch, record, true, commonCfg.OPERATIONS.DELETE)
    }

    const renderUpdate = (refetch, record) => {
        return renderOperation(refetch, record, false, commonCfg.OPERATIONS.UPDATE)
    }

    return (
        <Display
            {...props}
            colWidth={apiRolesCfg.KEYS.map(key => key.width)}
            actionWidth={apiRolesCfg.ACTIONWIDTH}
            keys={apiRolesCfg.KEYS.map(key => key.name)}
            operations={apiRolesCfg.OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={false}
            renderCreate={(envName, refetch) => renderCreate(refetch)}
            renderUpdate={(envName, refetch, records) => renderUpdate(refetch, records)}
            renderDelete={(envName, refetch, records) => renderDelete(refetch, records)}
        />
    );
};
export default ApiRoles;