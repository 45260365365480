import React from "react";
import { Input, InputGroup, Modal, Button, Form, Panel, Schema } from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import isArray from 'lodash/isArray';
import EnvPicker from "../EnvPicker";
import EventIdType from "../EventIdType";
import { playFetch } from "../../../lib/playfetch";
import { formCfg, eventIdType } from "../config";

const rule = Schema.Types.StringType().isRequired('This field is required.')

const ExternalRef = ({...props}) => {
    const [open, setOpen] = React.useState(false);
    const [envName, setEnvName] = React.useState("");
    const [id, setId] = React.useState("");
    const [idType, setIdType] = React.useState(eventIdType.RACEID);
    const [externalRef, setExternalRef] = React.useState("");
    const [formError, setFormError] = React.useState({});
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    React.useEffect(() => {
        if (open === true) {
            setEnvName("")
            setIdType(eventIdType.RACEID)
            setId("")
            setSubmitDisabled(true)
        }
    }, [open]);

    const envNameChangeHandler = (value) => {
        if (value.length === 0) {
            setEnvName("")
        } else {
            setEnvName(value[0])
        }
    };

    const idTypeChangeHandler = (value) => {
        setIdType(value);
    };

    const idChangeHandler = (value) => {
        setId(value);
    };

    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setOpen(true)
    }

    const fetchExternalRef = () => {
        const formData = new FormData();
        if (idType === eventIdType.RACEID) {
            formData.append("dbquery", `select external_ref from race where id='${id}';`)
        } else if (idType === eventIdType.MATCHID) {
            formData.append("dbquery", `select external_ref from match where id='${id}';`)
        } else if (idType === eventIdType.COMPETITIONID) {
            formData.append("dbquery", `select external_ref from competition where id='${id}';`)
        }

        const apiUrl = `core/dbquery/readonly/${envName}`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                setOpen(false)
                if (isArray(data)) {
                    setExternalRef(data[0]["external_ref"]);
                } else {
                    setExternalRef("")
                }
            });
    }

    const handleClose = () => {
        setOpen(false)
    }

    React.useEffect(() => {
        if ((envName.length > 0) &&
            (id.length > 0)) {
                setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
        setExternalRef("")
    }, [envName, id]);

    React.useEffect(() => {
        props.onChange(externalRef, props.name)
        // eslint-disable-next-line
    }, [externalRef]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled) || (externalRef.length !== 0));

    return (
        <>
            <InputGroup style={{ width: formCfg.WIDTH }}>
                <Input
                    value={externalRef}
                    onChange={setExternalRef}
                />
                <InputGroup.Button onClick={handleSearch}>
                    <SearchIcon />
                </InputGroup.Button>
            </InputGroup>
            <Modal
                backdrop={true}
                keyboard={false}
                open={open}
                onClose={handleClose}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Panel shaded bordered>
                    <Modal.Header closeButton={false}>
                        <Modal.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                            Extract external reference from ID
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onCheck={handleFormCheck} >
                            <Form.Group controlId={"environment"}>
                                <Form.ControlLabel><b>Select Environment:</b></Form.ControlLabel>
                                <Form.Control
                                    name="envName"
                                    value={envName}
                                    multiPick={false}
                                    type={['core/ALL', 'wincore/ALL']}
                                    accepter={EnvPicker}
                                    onChange={envNameChangeHandler}
                                />
                            </Form.Group>
                            <Form.Group controlId={"id"}>
                                <Form.ControlLabel><b>Select Event Id Type:</b></Form.ControlLabel>
                                <Form.Control
                                    name="eventIdType"
                                    value={idType}
                                    accepter={EventIdType}
                                    onChange={idTypeChangeHandler}
                                    rule={rule}
                                    errorPlacement="bottomStart"
                                    style={{ width: formCfg.WIDTH }}
                                />
                            </Form.Group>
                            <Form.Group controlId={"id"}>
                                <Form.ControlLabel><b>Enter Event Id:</b></Form.ControlLabel>
                                <Form.Control
                                    name="eventId"
                                    value={id}
                                    accepter={Input}
                                    onChange={idChangeHandler}
                                    rule={rule}
                                    errorPlacement="bottomStart"
                                    style={{ width: formCfg.WIDTH }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={fetchExternalRef}
                            appearance="primary"
                            disabled={isSubmitDisabled}
                        >
                            Extract
                        </Button>
                        <Button onClick={handleClose} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Panel>
            </Modal>
        </>
    );
};

export default ExternalRef;
