import { commonCfg } from "../Common/config";

export const offeringsCfg = {
    KEYS: [
        {
            name: "competition",
            width: 8
        },
        {
            name: "country",
            width: 4
        },
        {
            name: "sport",
            width: 4
        }
    ],
    ACTIONWIDTH: 8,
    OPERATIONS: [
        commonCfg.OPERATIONS.UPDATE
    ]
}