export const appCfg = {
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
    GITOPS_ALLOWED_ROLES: ["Platform", "Gitops", "Gitops-RO"],
    DATABASE_ALLOWED_ROLES: ["Platform", "DB-RO", "DB-RW", "DB-Approvers"],
    AUTH_CLIENT_ID: "182717441315-17m34bf9b72oq9o3oe0k8s2dh67c6d60.apps.googleusercontent.com",
    ENCRYPT_PASS: "bBd548JZBAkfwoUJUwzj8oF55NBhMgipGW258t8GynYMFSD3ZG",
    IDLE_TIMEOUT_IN_MINUTES: 15
}

export const projectTypes = {
    CORE: "core",
    CASINO: "casino"
}
