import React from "react";
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
import Login from '../components/Login';
import Header from '../components/Header';
import { getProjectId, isAuthenticated } from '../components/Authentication';
import { appCfg, projectTypes } from '../config/app';

const Home = () => {
    const [projectType, setProjectType] = React.useState(getProjectId())

    if (isAuthenticated()) {
        return <Navigate to="/dashboard" replace />;
    }

    const onChangeProjectTypeHandler = (value) => {
        if(value === true) {
            const cookieData = CryptoJS.AES.encrypt(
                JSON.stringify({'project_id': projectTypes.CASINO}),
                appCfg.ENCRYPT_PASS
            ).toString();
            Cookies.set('project_id', cookieData)

        } else {
            Cookies.remove('project_id')
        }
        setProjectType(getProjectId())
    };

	return (
        <>
            <Header projectType={projectType} changeProjectType={onChangeProjectTypeHandler}/>
		    <div className='main'>
			    <div className='area' style={{ width: "100%", justifyContent: "center", display: "flex" }} >
				    <Login />
			    </div>
		    </div>
        </>
	);
};

export default Home;
