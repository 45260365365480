import React from "react";
import { Popover, Dropdown } from 'rsuite';

const ArgocdMenu = React.forwardRef(({ onSelect, ...rest }, ref) => (
	<Popover
		arrow={false}
		ref={ref}
		{...rest}
		full
		style={{ margin: "-2px",
				 borderRadius: "20px",
				 border: "5px solid gray",
				 backgroundColor: "#f5f4f2" }}
	>
		<Dropdown.Menu
			onSelect={onSelect}
			style={{ borderRadius: "15px",
					 backgroundColor: "#f5f4f2" }}
		>
			<Dropdown.Item eventKey="argocd_refresh_apps">Refresh Apps</Dropdown.Item>
            <Dropdown.Item eventKey="argocd_scale_apps">Scale Apps</Dropdown.Item>
		</Dropdown.Menu>
	</Popover>
));

export default ArgocdMenu;