import React, { useEffect, useState } from "react";
import {
    Container,
    Header,
    Content,
    Footer,
    Stack,
    IconButton,
    Toggle,
    Button,
    Divider,
    Progress,
    Message,
    useToaster,
    InputGroup,
    Input,
    Checkbox
} from 'rsuite';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useStopwatch } from 'react-use-precision-timer';
import rehypePrism from "rehype-prism-plus";
import PlayIcon from '@rsuite/icons/legacy/Play';
import ReloadIcon from '@rsuite/icons/Reload';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import { find } from "lodash";
import EnvPicker from "../FormGroup/EnvPicker";
import FormResponse from '../FormResponse';
import { playFetch } from '../../lib/playfetch';
import { getUserRole } from "../Authentication";
import { formCfg } from "../FormGroup/config";

const DbForm = ({...props}) => {
    const toaster = useToaster();
    const [dbQuery, setDbQuery] = useState((props.prefilledJob === false) ? "" : props.dbQuery);
    const [envName, setEnvName] = useState((props.prefilledJob === false) ? [] : props.envName);
    const [remarks, setRemarks] = useState((props.prefilledJob === false) ? "" : props.remarks);
    const [readWriteAccess, setReadWriteAccess] = useState(props.prefilledJob === true)
    const [executeDisabled, setExecuteDisabled] = useState(true);
    const [runInBackground, setRunInBackground] = useState(false);
    const [runAsSuperUser, setRunAsSuperUser] = useState(false);
    const [runInParallel, setRunInParallel] = useState(true);
    const [autoCommitEnabled, setAutoCommitEnabled] = useState(true);
    const [loadResult, setLoadResult] = useState(false);
    const [result, setResult] = useState([]);
    const stopwatch = useStopwatch();
    const [execTime, setExecTime] = useState(0);

    const envNameChangeHandler = (value) => {
        if (props.prefilledJob !== true) {
            setEnvName(value);
        }
    };

    const resetHandler = (event) => {
        event.preventDefault();
        reset();
    };

    const reset = () => {
        props.resetMe()
        setEnvName([])
        setDbQuery("")
        setRemarks("")
        setReadWriteAccess(false)
        setRunInBackground(false)
        setRunAsSuperUser(false)
        setRunInParallel(true)
        setAutoCommitEnabled(true)
        setExecTime(0)
        stopwatch.stop()
    };

    const callReadOnlyApi = (envIndex) => {
        const formData = new FormData();
        formData.append("dbquery", dbQuery.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "").trim())

        let apiUrl = `core/dbquery/readonly/${envName[envIndex]}`
        
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                /* convert data to array */
                let data_array = []
                if(Array.isArray(data)) {
                    data_array = data
                } else {
                    data_array.push(data)
                }

                let newResults = result;
                newResults[envIndex] = data_array
                setResult(newResults);
                if (newResults.includes(undefined) === false) {
                    setLoadResult(false)
                    stopwatch.stop()
                }
            });
    }

    const callPrimaryApi = () => {
        const formData = new FormData();
        formData.append("run_in_background", runInBackground)
        formData.append("run_in_parallel", runInParallel)
        formData.append("auto_commit_enabled", autoCommitEnabled)

        let apiUrl = `core/dbquery/primary/${props.approvalId}`

        playFetch(apiUrl, "POST", formData)
            .then(data => {
                if (data.hasOwnProperty("detail")) {
                    const msg_type = 'error'
                    const msg = data['detail']['user_msg']
                    const message = (
                        <Message showIcon type={msg_type} closable>
                            <b>{msg}</b>
                        </Message>
                    );
                    toaster.push(message, { placement: 'topEnd', duration: 10000 })
                    reset()
                } else {
                    if (runInBackground === false) {
                        /* show response here */
                        const newResults = envName.map((env, index) => {
                            const env_result = find(data, ['env', env])
                            /* convert data to array */
                            let data_array = []
                            if(Array.isArray(env_result["data"])) {
                                data_array = env_result["data"]
                            } else {
                                data_array.push(env_result["data"])
                            }
                            return data_array
                        })
                        setResult(newResults);
                        if (newResults.includes(undefined) === false) {
                            setLoadResult(false)
                        }
                    } else {
                        /* show only toaster */
                        let msg_type = 'error';
                        let msg = data.reason;
                        if(data.status === 200) {
                            msg_type = 'success';
                            msg = data.msg;
                        } else {
                            msg = data.detail.msg
                        }
                        const message = (
                            <Message showIcon type={msg_type} closable>
                                <b>{msg}</b>
                            </Message>
                        );
                        toaster.push(message, { placement: 'topEnd', duration: 10000 })
                        reset()
                    }
                }
            });
    }

    const executeHandler = (event) => {
        event.preventDefault();
        setExecTime(0)
        setResult(Array(envName.length).fill(undefined))
        setLoadResult(true);
        if (readWriteAccess === false) {
            stopwatch.start()
            envName.map((item, index) => callReadOnlyApi(index))
        } else {
            callPrimaryApi()
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("dbquery", dbQuery.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "").trim())
        formData.append("remarks", remarks.trim())
        formData.append("run_as_su", runAsSuperUser)

        envName.forEach(env => {
            formData.append("update_envs", env) 
        });
        
        const apiUrl = `generic/jobs/approval/request`

        playFetch(apiUrl, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                if(data.status === 200) {
                    msg_type = 'success';
                    msg = data.msg;
                } else {
                    msg = data.detail.msg
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        <b>{msg}</b>
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 10000 })
            }).finally(() => {
                reset()
            });
    };

    const readWriteAccessHandler = (checked, event) => {        
        setReadWriteAccess(checked)
    };

    useEffect(() => {
        setEnvName(props.envName)
        setDbQuery(props.dbQuery)
        setRemarks(props.remarks)
        setReadWriteAccess(props.prefilledJob)
        setRunInParallel((props.envName.length === 1) ? false : true)
        setResult([])
        setLoadResult(false)
        setExecTime(0)
    }, [props.prefilledJob, props.dbQuery, props.envName, props.remarks]);

    useEffect(() => {
        setExecuteDisabled(true)
        if ((envName.length > 0) && (dbQuery.length > 0)) {
            if (readWriteAccess === true) {
                if (remarks.length > 0) {
                    setExecuteDisabled(false)
                }
            } else {
                setExecuteDisabled(false)
            }
        }
        setResult([])
        setExecTime(0)
        setLoadResult(false)
    }, [envName, dbQuery, readWriteAccess, remarks]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (stopwatch.isRunning()) {
                setExecTime(stopwatch.getElapsedStartedTime())
            }
        }, 1);
        return () => clearInterval(intervalId);
    }, [loadResult, stopwatch]);

    const showExecuteButtonElement = () => {
        return (
            <Button
                color="red"
                size="md"
                appearance="primary"
                disabled={executeDisabled || loadResult}
                loading={loadResult}
                onClick={((props.prefilledJob === true) || (readWriteAccess === false)) ? executeHandler : submitHandler}
                style={{ borderRadius: "20px" }}
            >
                <div style={{ color: "white" }} >
                    {((readWriteAccess === true) && (props.prefilledJob === false)) ? "Submit" : "Execute"}
                    &nbsp;<PlayIcon size="5em" />
                </div>
            </Button>
        )
    }

    return (
        <div>
            <Container>
                <Header>
                    <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Stack spacing={1}>
                            <b>Environment:</b>&nbsp;&nbsp;
                            <EnvPicker
                                name="envName"
                                value={envName}
                                multiPick={true}
                                type={['core/ALL', 'wincore/ALL', 'sreplaybook']}
                                label="Environment"
                                onChange={envNameChangeHandler}
                            />
                        </Stack>
                        <Stack spacing={6}>
                            <Toggle
                                size="lg"
                                checked={readWriteAccess}
                                readOnly={!getUserRole().some(role => (role === "DB-RW")) || props.prefilledJob}
                                onChange={readWriteAccessHandler}
                                checkedChildren="Read-Write"
                                unCheckedChildren="Read-Only"
                            />
                        </Stack>
                    </Stack>
                    {(readWriteAccess === true) && <>
                        {(props.prefilledJob === false) && <Message
                            showIcon
                            type="info"
                            style={{
                                "--rs-message-info-icon": "#ffffff",
                                "--rs-message-info-text": "#ffffff",
                                "--rs-message-info-bg": "red",
                                marginTop: "10px"
                            }}
                        >
                            <b>
                                {'Please be aware that all the write requests go through the approval process. \
                                    The query will not be executed against primary database as such.'}
                            </b>
                        </Message>}
                        {(props.prefilledJob === true) && <Message
                            showIcon
                            type="info"
                            style={{
                                "--rs-message-info-icon": "#000000",
                                "--rs-message-info-text": "#000000",
                                "--rs-message-info-bg": "#E9C9C9",
                                marginTop: "10px"
                            }}
                        >
                            <>
                                {'This page contains read-only elements which can only be executed without modification.'}
                            </>
                        </Message>}
                    </>}
                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Stack spacing={6}>
                            {(props.prefilledJob === false) && <Stack spacing={6}>
                                <IconButton
                                    icon={<FolderFillIcon />}
                                    appearance="primary"
                                    color="yellow"
                                    circle
                                />
                                {(readWriteAccess === true) && <>
                                    <div
                                        style={{
                                            border: "1px solid gray",
                                            borderRadius: "20px",
                                            paddingRight: "20px",
                                            paddingLeft: "10px"
                                        }}
                                    >
                                        <Checkbox
                                            checked={runAsSuperUser}
                                            onChange={(value, checked, event) => setRunAsSuperUser(checked)}
                                        >
                                            <i><b>Run as super user</b></i>
                                        </Checkbox>
                                    </div>
                                </>}
                            </Stack>}
                            {(props.prefilledJob === true) && <>
                                <div
                                    style={{
                                        border: "1px solid gray",
                                        borderRadius: "20px",
                                        paddingRight: "20px",
                                        paddingLeft: "10px"
                                    }}
                                >
                                    <Checkbox
                                        checked={runInBackground}
                                        onChange={(value, checked, event) => setRunInBackground(checked)}
                                    >
                                        <i><b>Run in background</b></i>
                                    </Checkbox>
                                    {(props.envName.length > 1) && <>
                                        <Divider vertical style={{ marginRight: "5px" }} />
                                        <Checkbox
                                            checked={runInParallel}
                                            onChange={(value, checked, event) => setRunInParallel(checked)}
                                        >
                                            <i><b>Run in parallel</b></i>
                                        </Checkbox>
                                    </>}
                                    <Checkbox
                                        checked={autoCommitEnabled}
                                        onChange={(value, checked, event) => setAutoCommitEnabled(checked)}
                                    >
                                        <i><b>Auto Commit</b></i>
                                    </Checkbox>
                                </div>
                            </>}
                        </Stack>
                        <Stack spacing={6}>
                            {(readWriteAccess === true) && <InputGroup
                                size="md"
                                style={{
                                    outline: "none",
                                    border: "1px solid gray",
                                    borderRadius: "20px",
                                    "&:focus": {
                                        outline: "none"
                                    }
                                }}
                            >
                                <Input
                                    placeholder="Please enter reason / justification."
                                    value={remarks}
                                    onChange={(value, event) => setRemarks(value)}
                                    readOnly={props.prefilledJob === true}
                                    style={{
                                        outline: "none",
                                        paddingRight: "0px",
                                        borderRadius: "20px",
                                        width: formCfg.WIDTH,
                                        fontStyle: "italic",
                                        "&:focus": {
                                            outline: "none"
                                        }
                                    }}
                                />
                                {showExecuteButtonElement()}
                            </InputGroup>}
                            {(readWriteAccess === false) && <>{showExecuteButtonElement()}</>}
                            <Button
                                color="green"
                                size="md"
                                appearance="primary"
                                disabled={loadResult}
                                onClick={resetHandler}
                                style={{ borderRadius: "20px" }}
                            >
                                <div style={{ color: "white" }} >
                                    Reset&nbsp;<ReloadIcon size="5em" />
                                </div>
                            </Button>
                        </Stack>
                    </Stack>
                </Header>
                <Content style={{ marginTop: "10px", border: "solid gray 2px" }}>
                    { (loadResult === true) && <Progress.Line
                                                    percent={(execTime * 100)/40000}
                                                    showInfo={false} /> }
                    <CodeEditor
                        value={dbQuery}
                        language="sql"
                        data-color-mode="light"
                        onChange={(evn) => setDbQuery(evn.target.value)}
                        readOnly={props.prefilledJob === true}
                        padding={15}
                        minHeight={300}
                        rehypePlugins={[
                            [rehypePrism, { ignoreMissing: true }]
                        ]}
                        style={{
                            fontSize: 18,
                            fontFamily: "'Source Code Pro', SFMono-Regular, Menlo, Monaco, Consolas, 'Courier New', monospace, 'Liberation Mono'"
                        }}
                    />
                </Content>
                <Footer>
                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    {((result.length !== 0) && (loadResult === false)) && <FormResponse
                                                                                name={envName}
                                                                                response={result}
                                                                                feedStep={false} /> }
                </Footer>
            </Container>
        </div>
    );
};
export default DbForm;
