import React, { useState, useEffect } from "react";
import { Panel, FlexboxGrid, ButtonToolbar, IconButton, Badge } from 'rsuite';
import RandomIcon from '@rsuite/icons/Random';
import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';
import ListIcon from '@rsuite/icons/List';
import Snapshot from "./snapshot";
import Status from "./status";
import Promote from "./Edit/Promote";
import { gitGroups } from "./config";
import { isLiveEnv, isReadOnlyEnv } from "../../../lib/gitops";
import EnvCfg from "./EnvCfg";
import { getEnvColour } from "./utils";
import { getEnvironments } from "../../Authentication";

const Env = ({...props}) => {
	const [openPromote, setOpenPromote] = useState(false);
    const [openEnvCfg, setOpenEnvCfg] = useState(false);
    const [appView, setAppView] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [isLive, setIsLive] = useState(false);

    const promoteHandler = (event) => {
		event.preventDefault();
		setOpenPromote(true);
	};

    const envCfgHandler = (event) => {
		event.preventDefault();
		setOpenEnvCfg(true);
	};

	const appViewHandler = (event) => {
		event.preventDefault();
		setAppView(!appView);
	};

	useEffect(() => {
        async function markEnvMetaData() {
            const environments = await getEnvironments();
            let readOnly = true;
            if (props.group === gitGroups.WINCORE) {
                setIsLive(isLiveEnv(props.name.split('-').shift(), environments?.wincore))
			    readOnly = isReadOnlyEnv(props.name.split('-').shift(), environments?.wincore)
		    } else {
                setIsLive(isLiveEnv(props.name, environments?.core))
			    readOnly = isReadOnlyEnv(props.name, environments?.core)
			}
            /* Override if RO passed via props */
            if (props.readOnly === true) {
                readOnly = true;
            }
            setIsReadOnly(readOnly)
        }
        markEnvMetaData()
	}, [props.name, props.group, props.readOnly]);

	const header = <FlexboxGrid justify="start" align="middle">
						<FlexboxGrid.Item colspan={16}>
							<div style={{ width: "200px",
                                          borderBottom: "1px solid gray",
										  borderRadius: "16px" }}
							>
								<Status key={`env-${props.name}`} status={props.status} size='1.5em' />&nbsp;&nbsp;
								{ (isLive === true) ? <Badge content="LIVE">
									<><p style={{ marginRight: '25px' }}>
										{props.name}
									</p></>
								</Badge> : props.name }
							</div>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={8}>
							<FlexboxGrid justify="end" align="middle">
								<FlexboxGrid.Item colspan={20}>
									<div style={{ display: "flex",
										  		  alignItems: "center",
										  		  justifyContent: "right" }}>
										<ButtonToolbar>
                                            <IconButton
												icon={<ListIcon color="#4C4E52" />}
												circle
												size="xs"
												color="cyan"
												appearance="primary"
												onClick={envCfgHandler}
											/>
                                            <IconButton
												icon={<RandomIcon color="#4C4E52" />}
												circle
												size="xs"
												color="cyan"
												appearance="primary"
												disabled={isReadOnly}
												onClick={promoteHandler}
											/>
                                            <IconButton
												icon={(appView === true) ? <MinusIcon color="#4C4E52" /> : <PlusIcon color="#4C4E52" />}
												circle
												size="xs"
												color="cyan"
												appearance="primary"
												onClick={appViewHandler}
											/>
    									</ButtonToolbar>
									</div>
								</FlexboxGrid.Item>
							</FlexboxGrid>
						</FlexboxGrid.Item>
					</FlexboxGrid>

	return (
		<>
			<Panel shaded header={header} style={{ backgroundColor: getEnvColour(props.name), width: "400px" }}>
				{Object.values(props.apps).map((item, index) => {
                    if ((appView === true) && (item.hasOwnProperty('modules') === true)) {
                        return Object.values(item.modules).map((subItem, subIndex) => <Snapshot
                                    name={subItem.name}
                                    key={`sub-status-${subIndex}`}
                                    env={props.name}
                                    group={props.group}
                                    app={subItem}
                                    readOnly={isReadOnly}
                                    marginBottom={5} />)
                    } else {
                        return <Snapshot
									name={item.name}
									key={`status-${index}`}
									env={props.name}
									group={props.group}
									app={item}
                                    readOnly={isReadOnly}
									marginBottom={5} />
                    }
                })}
			</Panel>
			{ (openPromote === true) && <>
				<Promote
					open={openPromote}
					onClose={() => setOpenPromote(false)}
					group={props.group}
					env={props.name}
					apps={props.apps}
					rawData={props.rawData}
				/>
			  </>
			}
            { (openEnvCfg === true) && <>
				<EnvCfg
					open={openEnvCfg}
					onClose={() => setOpenEnvCfg(false)}
					group={props.group}
					env={props.name}
                    readOnly={isReadOnly}
				/>
			  </>
			}
		</>
	);
};

export default Env;
