import React from "react";
import { Button } from 'rsuite';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAuthenticated, getUserId, logout } from "../Authentication"

const Logout = () => {
  const navigate = useNavigate();
  let userId = undefined
  if (isAuthenticated()) {
    userId = getUserId()
  }

  const onLogout = async () => {
    await logout()
    navigate("/", { replace: true })
  }

  if (userId === undefined) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <div>
        <Button
            block
            appearance="subtle"
            onClick={() => onLogout()}
            style={{
                "--rs-btn-subtle-text": "var(--rs-btn-subtle-hover-text)"
            }}
        >
            Log out
        </Button>
      </div>
    );
  }
};

export default Logout;
