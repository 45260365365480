import React from "react";
import { Input, InputGroup, Drawer, Panel, ButtonToolbar, IconButton, Loader } from "rsuite";
import SaveIcon from '@rsuite/icons/legacy/Save';
import SearchIcon from '@rsuite/icons/Search';
import EditIcon from '@rsuite/icons/Edit';
import VanillaJsonEditor from "../../VanillaJsonEditor";
import PlaybookStep from "../../Playbook/step";
import { formCfg } from "../config";

const FeedStep = ({...props}) => {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [src, setSrc] = React.useState({json: undefined, text: undefined});

    const handleSearch = (event) => {
        event.preventDefault();
        setSearch(true)
        setOpen(true)
    }

    const handleEdit = (event) => {
        event.preventDefault();
        setEdit(true)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSearch(false)
        setEdit(false)
        if(props.value === '') {
            setSrc({json: undefined, text: undefined})
        } else {
            setSrc({json: JSON.parse(props.value), text: undefined})
        }
    }

    const handleSave = () => {
        let updatedSrc = undefined
        if(src["json"] !== undefined) {
            updatedSrc = src["json"]
        } else if(src["text"] !== undefined) {
            updatedSrc = JSON.parse(src["text"])
        }

        props.onChange(JSON.stringify(updatedSrc), props.name)
    }

    const onEdit = (updatedContent, previousContent, { contentErrors, patchResult }) => {
        setSrc(updatedContent)
    }

    const setFeederData = (data) => {
        if(data !== undefined) {
            setSrc({json: data[0], text: undefined})
            setSearch(false)
            setEdit(true)
        }
    }

    return (
        <>
            <InputGroup style={{ width: formCfg.WIDTH }}>
                <Input
                    readOnly
                    value={props.value}
                />
                { ((src['json'] === undefined) && (src['text'] === undefined)) && <InputGroup.Button onClick={handleSearch}>
                    <SearchIcon />
                </InputGroup.Button> }
                { ((src['json'] !== undefined) || (src['text'] !== undefined)) && <InputGroup.Button onClick={handleEdit}>
                    <EditIcon />
                </InputGroup.Button> }
            </InputGroup>
            <Drawer
                open={open}
                onClose={handleClose}
                size="lg"
                backdrop="static"
            >
                { (search === true) && <>
                <Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        { props.type }
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <PlaybookStep props={{ playbookId: props.playbookId, feedStep: true, feeder: setFeederData }} />
                </Drawer.Body> 
                </> }
                { (edit === true) && <>
                <Drawer.Header>
                    <Drawer.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                        { props.type.replace('Fetch', 'Edit') }
                    </Drawer.Title>
                    <Drawer.Actions>
                        <ButtonToolbar>
                            <IconButton circle icon={<SaveIcon />} onClick={handleSave} />
                            <IconButton circle icon={<SearchIcon />} onClick={handleSearch} />
                        </ButtonToolbar>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    {((src['json'] === undefined) && (src['text'] === undefined)) ? (
                        <Loader size="md" content="Loading..." />
                    ) : (
                        <Panel
                            bordered
                            bodyFill
                            shaded
                        >
                            <VanillaJsonEditor
                                props={{
                                    content: src,
                                    readOnly: false,
                                    onChange: onEdit
                                }}
                            />
                        </Panel>
                    )}
                </Drawer.Body> 
                </> }
            </Drawer>
        </>
    );
};

export default FeedStep;
