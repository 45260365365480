import { useState, useEffect } from 'react';
import { Divider, Steps, Button, Container, Sidebar, Header } from 'rsuite';
import PlaybookStep from './step';
import { playbooks } from "../../config/playbooks";

const PlaybookSteps = ({ props }) => {
    const [step, setStep] = useState(0);
    
    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > (props.stepIds.length - 1) ? (props.stepIds.length - 1) : nextStep);
    };

    useEffect(() => {
		if (playbooks[props.playbookId].steps[step].hasOwnProperty('help')) {
            props.setHelpText(playbooks[props.playbookId].steps[step].help)
        }
	}, [step, props]);

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    if (props.stepIds.length === 0) {
        return (<></>);
    }

    return (
        <>
            <Divider />
            <Container>
                <Sidebar>
                    <Steps vertical current={step}>
                        {props.stepIds.map((item, index) => <Steps.Item title={playbooks[props.playbookId].steps[item].name} key={index} />)}
                    </Steps>
                </Sidebar>
                <Container className='steps'>
                    <Header>
                            <Button onClick={onPrevious} disabled={step === 0} style={{}} >
                                Previous Step
                            </Button>
                            <Button onClick={onNext} 
                                    disabled={step === (props.stepIds.length - 1)}
                                    style={{ position:"absolute", right: "10px"}} >
                                Next Step
                            </Button>
                    </Header>
                    <PlaybookStep props={{ playbookId: props.playbookId, stepId: props.stepIds[step] }} />
                </Container>
            </Container>
        </>
    );
};

export default PlaybookSteps;