import { useState, useEffect } from 'react';
import { SelectPicker, FlexboxGrid, Button, Message } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { playbooks } from "../../config/playbooks";
import Playbook from '../../components/Playbook';
import SessionTimer from '../../components/SessionTimer';
import Header from '../../components/Header';
import { getUserRole, getProjectId } from '../../components/Authentication';

const Dashboard = ({...props}) => {
	const [showPlaybook, setShowPlaybook] = useState(false)
	const [playbookId, setPlaybookId] = useState(null)
	const [playData, setPlayData] = useState([])
    const [showHelp, setShowHelp] = useState(false)
    const [helpText, setHelpText] = useState('')

	const playbookChangeHandler = (value, event) => {
        setShowHelp(false)
		if (value === null) {
            setShowPlaybook(false)
			setPlaybookId(null)
            setHelpText('')
		} else {
			setPlaybookId(value)
			setShowPlaybook(true)
            if (playbooks[value].hasOwnProperty('help')) {
                setHelpText(playbooks[value].help)
            } else {
                setHelpText('')
            }
		}
	};

	const playbookEnteringHandler = () => {
        setShowHelp(false)
        setShowPlaybook(false)
		setPlaybookId(null)
        setHelpText('')
	};

	useEffect(() => {
		const playData = playbooks.map((item, index) => {
			if ((item.category.includes(getProjectId())) &&
                (getUserRole().some(role => item.roles.includes(role)))) {
				return ({ label: item.name, value: index });
			}
			return undefined;
		}).filter(Boolean);
		setPlayData(playData)
	}, []);

	return (
		<>
			<SessionTimer />
            <Header />
			<div className='main'>
				<div className='area' style={{ width: "100%" }} >
                    {(showHelp) &&
                        <Message
                            showIcon
                            type="info"
                            closable
                            onClose={() => setShowHelp(false)}
                            header={playbooks[playbookId].summary}
                            style={{ marginBottom: "15px" }}
                        >
                            {helpText}
                        </Message>
                    }
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={23}>
					        <SelectPicker
						        label="Playbook"
						        block
						        onChange={playbookChangeHandler}
						        onEntering={playbookEnteringHandler}
						        data={playData} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1}>
                            <FlexboxGrid justify="end">
                                <Button
                                    startIcon={<HelpOutlineIcon />}
                                    onClick={() => setShowHelp(true)}
                                    disabled={(playbookId === null)}
                                >
                                    Help
                                </Button>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
					{(showPlaybook) && <Playbook playbookId={playbookId} setHelpText={setHelpText} />}
				</div>
			</div>
		</>
	);
};

export default Dashboard;
