export const recordsCfg = {
    TYPE: {
        APIROLES: "apiroles",
        APIUSERS: "apiusers",
        FEATUREDMATCH: "featuredmatch",
        FEATUREDRACE: "featuredrace",
        GLOBALOFFERINGS: "globalofferings",
        JOBS: "jobs",
        MBLTHRESHOLD: "mblthreshold",
        PRICEADJUST: "priceadjust",
        QUICKLINKS: "quicklinks"
    }
}