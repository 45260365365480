import React, { useEffect, useState } from "react";
import { Form, Schema, FlexboxGrid, Message, useToaster, Input, DatePicker, MaskedInput, InputGroup } from 'rsuite';
import split from 'lodash/split';
import get from 'lodash/get';
import isString from 'lodash/isString';
import EnvPicker from "../../FormGroup/EnvPicker";
import CustomPicker from "../../FormGroup/CustomPicker";
import { priceAdjustCfg } from "./config"
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";

const envNameRule = Schema.Types.ArrayType().isRequired('This field is required.');

const Create = ({...props}) => {
    const toaster = useToaster();
    const [envName, setEnvName] = useState([]);
    const [newRecord, setNewRecord] = useState({});
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const changeHandler = (value, name) => {
        let newValue = value;
        let newObj = {}

        if((value === null) || (value === '')) {
            newValue = null
            switch(name) {
                case "race_type":
                    newObj["country_code"] = null;
                    newObj["meeting_type"] = null;
                    break;
                case "venue_code":
                    newObj["meeting_date"] = null;
                    break;
                case "country_code":
                    newObj["meeting_type"] = null;
                    break;
                default:
                    break;
            }
        } else if((isString(value))) {
            newValue = value.replace(/[A-Za-z]/g, (match) => {
                                return match.toUpperCase();
                            })
            switch(name) {
                case "race_type":
                    newObj["venue_code"] = null;
                    break;
                case "meeting_type":
                    newObj["country_code"] = "AUS";
                    break;
                case "country_code":
                    if(newValue !== "AUS") {
                        newObj["meeting_type"] = null;
                    }
                    break;
                case "venue_code":
                    newObj["race_type"] = null;
                    newObj["country_code"] = null;
                    newObj["meeting_type"] = null;
                    break;
                case "adjustment":
                    newValue = Math.round(value);
                    if(newValue < priceAdjustCfg.ADJUSTMENT_RANGE.MIN ||
                            newValue > priceAdjustCfg.ADJUSTMENT_RANGE.MAX) {
                        newValue = Math.min(
                                    priceAdjustCfg.ADJUSTMENT_RANGE.MAX,
                                    Math.max(priceAdjustCfg.ADJUSTMENT_RANGE.MIN, newValue))
                    }
                    break;
                default:
                    break;
            }
        }
        newObj[name] = newValue;
        setNewRecord({
            ...newRecord,
            ...newObj
        });
    }

    useEffect(() => {
        const nextRecord = {}
        props.keys.forEach(key => {
            switch(key.name) {
                case "adjustment":
                    nextRecord[key.name] = 0;
                    break;
                default:
                    nextRecord[key.name] = null;
                    break;
            }
        })
        setEnvName(props.envName)
        setNewRecord({
            ...newRecord,
            ...nextRecord
        });
        fetchCountryCodes()
        // eslint-disable-next-line
    }, []);

    const fetchCountryCodes = () => {
        const formData = new FormData();
        formData.append("dbquery", "select distinct country from venue order by country")

        const apiUrl = `core/dbquery/readonly/prod-winter-core`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                const codes = data.map((item) => {
                    return { value: item.country, label: item.country }
                })
                setCountryCodeOptions(codes)
            });
    }

    const renderInput = key => {
        let rule = null;
        let customOptions = null;
        switch(key) {
            case "race_type":
                customOptions = [
                    { value: 'HORSE', label: 'HORSE' },
                    { value: 'HARNESS', label: 'HARNESS' },
                    { value: 'GREYHOUND', label: 'GREYHOUND' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            accepter={CustomPicker}
                            multiPick={false}
                            data={customOptions}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "country_code":
                customOptions = countryCodeOptions;
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            disabled={(get(newRecord, "race_type", null) === null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "meeting_type":
                customOptions = [
                    { value: 'METRO', label: 'METRO' },
                    { value: 'NON-METRO', label: 'NON-METRO' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            disabled={((get(newRecord, "race_type", null) === null) || (get(newRecord, "country_code", null) !== "AUS"))}
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "meeting_date":
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            accepter={DatePicker}
                            value={get(newRecord, key, null)}
                            disabled={(get(newRecord, "venue_code", null) === null)}
                            oneTap
                            isoWeek
                            onChange={(value) => changeHandler(value, key)}
                            onClean={(event) => changeHandler(null, key)}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "market_type":
                rule = Schema.Types.StringType().isRequired('This field is required.');
                customOptions = [
                    { value: 'ALL', label: 'WIN/PLACE' },
                    { value: 'WIN', label: 'WIN' },
                    { value: 'PLACE', label: 'PLACE' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            rule={rule}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            case "adjustment":
                return (
                    <InputGroup>
                        <InputGroup.Button
                            onClick={() => changeHandler((parseInt(get(newRecord, key, 0), 10) - 1), key)}
                            disabled={(parseInt(get(newRecord, key, 0), 10) === priceAdjustCfg.ADJUSTMENT_RANGE.MIN)}
                        >
                            -
                        </InputGroup.Button>
                        <Input
                            value={get(newRecord, key, 0)}
                            readOnly
                            onChange={(value, event) => changeHandler(value, key)}
                            style={{ textAlign: "center" }}
                        />
                        <InputGroup.Button
                            onClick={() => changeHandler((parseInt(get(newRecord, key, 0), 10) + 1), key)}
                            disabled={(parseInt(get(newRecord, key, 0), 10) === priceAdjustCfg.ADJUSTMENT_RANGE.MAX)}
                        >
                            +
                        </InputGroup.Button>
                    </InputGroup>
                )
            case "venue_code":
                rule = Schema.Types.StringType()
                            .pattern(/^[A-Za-z]{3}-[A-Za-z]$/,'Please enter a valid venue code like FLM-R')
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            accepter={MaskedInput}
                            mask={[/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, '-', /[A-Za-z]/]}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(((value === '') ? null : value), key)}
                            rule={rule}
                            style={{ width: "100%", textAlign: "center" }}
                        />
                    </Form.Group>
                )
            case "time_period":
                rule = Schema.Types.StringType().isRequired('This field is required.');
                customOptions = [
                    { value: 'P1', label: 'P1 | > 12 hours from jump' },
                    { value: 'P2', label: 'P2 | 3-12 hours from jump' },
                    { value: 'P3', label: 'P3 | 1-3 hours from jump' },
                    { value: 'P4', label: 'P4 | <= 1hr from jump' }
                ];
                return (
                    <Form.Group controlId={key}>
                        <Form.Control
                            name={key}
                            data={customOptions}
                            accepter={CustomPicker}
                            multiPick={false}
                            value={get(newRecord, key, null)}
                            onChange={(value, event) => changeHandler(value, key)}
                            rule={rule}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                )
            default:
                return (<></>);
        }
    }

    const callApi = (envName) => {
        const formData = new FormData();

        props.keys.forEach(key => {
            const value = get(newRecord, key.name, null);
            if(value !== null) {
                switch(key.name) {
                    case "meeting_date":
                        formData.append(key.name, `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`)
                        break;
                    default:
                        formData.append(key.name, value)
                        break;
                }
            }
        });

        const apiUrl = `${props.apiUrl}/add/${envName}`
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                let msg_type = 'error';
                let msg = data.reason;
                if(data.status === 200) {
                    msg_type = 'success';
                    msg = data.msg;
                } else {
                    msg = `${split(envName, '-')[0]} - ${data.detail.msg}`
                }
                const message = (
                    <Message showIcon type={msg_type} closable>
                        <b>{msg}</b>
                    </Message>
                );
                toaster.push(message, { placement: 'topEnd', duration: 30000 })
            }).finally(() => {
                props.refetchData()
            });
    }

    const addHandler = () => {
        envName.map((item) => callApi(item))
    };

    return (
        <>
            <Form>
                <FlexboxGrid justify="space-between" className="add-records-row" style={{ display: "flex", margin: "5px" }}>
                    {(props.envName.length === 0) && <FlexboxGrid.Item colspan={6} key={`add-bookmaker`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            <Form.Group controlId="envName">
                                <Form.Control
                                    name="envName"
                                    accepter={EnvPicker}
                                    value={envName}
                                    multiPick={true}
                                    exclude={["dev-winter", "qat-winter", "staging-winter", "prod-winter"]}
                                    type={['core/ALL']}
                                    label="Environment"
                                    onChange={envNameChangeHandler}
                                    rule={envNameRule}
                                />
                            </Form.Group>
                        </div>
                    </FlexboxGrid.Item>}
                    {(props.keys.map((key, index) =>
                        <FlexboxGrid.Item colspan={key.width} key={`add-${index}`}>
                            <div className="add-records-col" style={{ display: "block" }}>
                                {renderInput(key.name)}
                            </div>
                        </FlexboxGrid.Item>))}
                    <FlexboxGrid.Item colspan={props.actionWidth} key={`add-action`}>
                        <div className="add-records-col" style={{ display: "block" }}>
                            <Confirm onConfirm={addHandler} onCancel={props.refetchData} />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        </>
    );
};
export default Create;
