import React from "react";
import Display from "../../Common/display";
import JobViewer from "../jobviewer";
import { approvalCfg } from "./config";
import { fetch } from "../../Common/lib/fetch";
import { envType } from "../../../FormGroup/config";

const Approval = ({...props}) => {
    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/approval/fetch/${env}`}
        })

        const data = await fetch(approvalCfg.KEYS.map(key => key.name), requestData);
        return data;
    }

    const renderCustomAction = (row, refetch) => {
        return (
            <>
                <JobViewer
                    refetch={refetch}
                    approval_id={row.approval_id}
                    query={row.query}
                />
            </>
        );
    }

    return (
        <>
            <Display
                {...props}
                displayEnv={true}
                selectEnv={false}
                showEnvPickerAsTab={true}
                envTypes={[envType.CORE, envType.WINCORE, envType.SREPLAYBOOK]}
                colWidth={approvalCfg.KEYS.map(key => key.width)}
                bookieWidth={6}
                actionWidth={approvalCfg.ACTIONWIDTH}
                keys={approvalCfg.KEYS.map(key => key.name)}
                operations={approvalCfg.OPERATIONS}
                fetchData={(envName) => fetchData(envName)}
                displayActionRecord={true}
                addGlobally={true}
                renderCustomAction={(row, refetch) => renderCustomAction(row, refetch)}
            />
        </>
    );
};
export default Approval;
