import React, { useState, useEffect } from "react";
import {
    Container,
    Header,
    Sidebar,
    Content,
    Nav,
    Row,
    Col,
    Divider,
    Loader,
    Panel,
    FlexboxGrid,
    ButtonToolbar,
    Button
} from "rsuite";
import ReactJson from 'react-json-view';
import CsvDownloadButton from 'react-json-to-csv';
import * as XLSX from "xlsx";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';
import { formCfg } from "../FormGroup/config";

const CustomNav = ({ name, activeKey, onSelect, ...props }) => {
    return (
        <Nav {...props} vertical activeKey={activeKey} onSelect={onSelect} style={{ width: (formCfg.WIDTH / 2) }}>
            {name.map((item, index) => <Nav.Item 
                                            key={index.toString()}
                                            eventKey={index.toString()}
                                            onSelect={onSelect}
                                            active={index === activeKey}
                                       >
                                            {item.slice(0, item.lastIndexOf('-'))}
                                       </Nav.Item>
            )}
      </Nav>
    );
};

const FormResponse = ({...props}) => {
    const [activeKey, setActiveKey] = useState(0);
    const [activeResult, setActiveResult] = useState(undefined)
    const [displayTable, setDisplayTable] = useState(false)

    const navSelectHandler = (eventKey, event) => {
        setActiveKey(parseInt(eventKey));
        setDisplayTable(false)
    };

    useEffect(() => {
        setActiveResult(props.response[activeKey])
        if(props.feedStep === true) {
            props.feeder(props.response[activeKey])
        }
    }, [props, activeKey]);

    const handleDownloadAsXls = () => {
        // create workbook and worksheet
        const workbook = XLSX.utils.book_new();

        props.response.forEach((res, index) => {
            const header = Object.keys(res);

            var wscols = [];
            header.forEach(head => {
                wscols.push({ wch: head.length + 25 })
            })

            const worksheet = XLSX.utils.json_to_sheet(res);
            worksheet["!cols"] = wscols

            XLSX.utils.book_append_sheet(workbook, worksheet, props.name[index]);
        });
        
        XLSX.writeFile(workbook, `Response-${Date.now()}.xlsx`, { compression: true });
    };

    if (props.response.length === 0) {
        return <React.Fragment />
    }

    return (
        <React.Fragment>
            <Container>
                <Header>
                    <h5>Response:</h5>
                    <div style={{ display: "flex",
                                  alignItems: "center",
                                  justifyContent: "right" }}>
                        <Button
                            onClick={handleDownloadAsXls}
                            style={{ borderRadius: "20px",
                                     border:"1px solid #4C4E52",
                                     backgroundColor: "transparent",
                                     fontSize:"12px",
                                     paddingTop: "0px",
                                     paddingBottom: "0px"}}
                        >
                            {"Export all as XLS workbook"}
                        </Button>
                    </div>
                    <Divider style={{ marginTop: "10px", marginBottom: "10px"}} />
                    <FlexboxGrid justify="end">
                        <FlexboxGrid.Item colspan={24}>
                            <div style={{ display: "flex",
                                          alignItems: "center",
                                          justifyContent: "right" }}>
                                {(activeResult !== undefined) && <ButtonToolbar>
                                    {(true === false) && <Button
                                        onClick={() => setDisplayTable(!displayTable)}
                                        style={{ borderRadius: "20px",
                                                 border:"1px solid #4C4E52",
                                                 backgroundColor: "transparent",
                                                 fontSize:"12px",
                                                 paddingTop: "0px",
                                                 paddingBottom: "0px"}}
                                    >
                                        {(displayTable === true) ? "Display as JSON" : "Display as Table"}
                                    </Button>}
                                    <CsvDownloadButton
                                        data={activeResult}
                                        filename={`data-${Date.now()}`}
                                        style={{ borderRadius: "20px",
                                                 border:"1px solid #4C4E52",
                                                 backgroundColor: "transparent",
                                                 fontSize:"12px",
                                                 padding:"11px 10px"}}
                                    >
                                        Export as CSV
                                    </CsvDownloadButton>
                                </ButtonToolbar> }
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Header>
                <Container>
                    { (props.feedStep !== true) && <Sidebar>
                        <div>
                            <Row>
                                <Col>
                                    <CustomNav 
                                        appearance="tabs"
                                        name={props.name}
                                        activeKey={activeKey}
                                        onSelect={navSelectHandler} />
                                </Col>
                            </Row>
                        </div>
                    </Sidebar> }
                    <Content>
                        <div>
                            <pre style={{whiteSpace: 'pre-wrap'}}>
                                {(activeResult === undefined) ? (
                                    <Loader size="md" content="Loading..." />
                                 ) : ((displayTable === true) ? (
                                        <div style={{ width: "25%" }}>
                                            <div style={{ width: "100%" }}>
                                            <ReactTabulator
                                                data={activeResult}
                                                autoColumns={true}
                                                layout="fitData"
                                                columns={Object.keys(activeResult[0]).map(key => {
                                                            return {"title": key, "field": key, minWidth:120}})}
                                            />
                                            </div>
                                        </div>
                                    ) : (
                                    activeResult.map((result, index) => (
                                        <Panel
                                            key={index}
                                            bordered
                                            bodyFill
                                            shaded
                                            style={{ marginBottom: 20 }}
                                        >
                                            {(displayTable === false) && <ReactJson
                                                src={result}
                                                name={false}
                                                theme="shapeshifter:inverted"
                                                indentWidth={2}
                                                collapsed={(props.feedStep === true) ? 1 : 2}
                                                enableClipboard={(props.feedStep === true) ? false : true }
                                                displayObjectSize={false}
                                                displayDataTypes={false}
                                                sortKeys={false}
                                            />}
                                        </Panel>
                                    ))
                                 ))}
                            </pre>
                        </div>
                    </Content>
                </Container>
            </Container>
        </React.Fragment>
    );
};

export default FormResponse;
