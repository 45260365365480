import React from 'react';
import { Schema, Form } from 'rsuite';

const Modules = ({...props}) => {
    const moduleShaChangeHandler = (value, event) => {
        const index = parseInt(event.target.name.split('-')[0], 10)
        const newSha = Array.from(props.moduleSha);
        newSha[index] = value
        props.onChange(newSha)
    }

    const rule = Schema.Types.StringType()
                    .pattern(/^\w{7}$/,'Please enter a valid commit sha')

    return (
        <>
            {Object.values(props.modules).map((item, index) => <>
                <Form.ControlLabel
                    key={`lbl-${index}`}
                    style={{ fontStyle: "italic", fontWeight: 'normal' }} >
                        {item.name}
                </Form.ControlLabel>
                <Form.Control
                    name={index.toString() + "-cntrl"}
                    key={`cntrl-${index}`}
                    value={props.moduleSha[index]}
                    rule={rule}
                    errorPlacement='topEnd'
                    onChange={(value, event) => moduleShaChangeHandler(value, event)}
                    style={{
                        width: "100px",
                        height: "20px",
                        borderRadius: "30px",
                        borderColor: (props.moduleSha[index] === item.sha) ? "green" : "red",
                        fontStyle: "italic",
                        fontWeight: 'normal' }} />
            </>)}
        </>    
    );
};

export default Modules;