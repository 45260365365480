import React from "react";
import { DatePicker } from "rsuite";
import { formCfg } from "../config";

const DateSelect = ({ ...props }) => {

  const dateChangeHandler = (value) => {
    if (value === null) {
      props.onChange('', props.name);
    } else {
      const newDate = value.getFullYear() + '-' + 
                          ('0' + parseInt(value.getMonth() + 1)).slice(-2) + '-' + 
                          ('0' + value.getDate()).slice(-2)
      props.onChange(newDate, props.name);
    }
  };

  return (
    <DatePicker
      oneTap
      isoWeek
      format="dd/MM/yyyy"
      onChange={dateChangeHandler}
      style={{ width: formCfg.WIDTH }} />
  );
};

export default DateSelect;
