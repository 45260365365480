import React from "react";
import Display from "../Common/display";
import { priceAdjustCfg } from "./config";
import { fetch } from "../Common/lib/fetch";
import { envType } from "../../FormGroup/config";
import Create from "./create";
import Delete from "./delete";

const PriceAdjust = ({...props}) => {
    const fetchData = async (envName) => {
        const requestData = envName.map((env) => {
            return {"envName": env, "apiUrl": `${props.apiUrl}/fetch/${env}`}
        })

        const data = await fetch(priceAdjustCfg.KEYS.map(key => key.name), requestData);

        return data;
    }

    const renderCreate = (envName, refetch) => {
        return <Create {...props} keys={priceAdjustCfg.KEYS} refetchData={refetch} envName={envName} />
    }

    const renderDelete = (envName, refetch, record) => {
        return <Delete {...props} records={record} refetchData={refetch} envName={envName} />
    }

    return (
        <Display
            {...props}
            displayEnv={true}
            selectEnv={false}
            envTypes={[envType.CORE]}
            colWidth={priceAdjustCfg.KEYS.map(key => key.width)}
            actionWidth={priceAdjustCfg.ACTIONWIDTH}
            bookieWidth={6}
            keys={priceAdjustCfg.KEYS.map(key => key.name)}
            operations={priceAdjustCfg.OPERATIONS}
            fetchData={(envName) => fetchData(envName)}
            displayActionRecord={true}
            renderCreate={(envName, refetch) => renderCreate(envName, refetch)}
            renderDelete={(envName, refetch, records) => renderDelete(envName, refetch, records)}
        />
    );
};
export default PriceAdjust;