import React from "react";
import { CheckboxGroup, Checkbox, Stack, Sidenav, Nav, AutoComplete, Affix } from 'rsuite';
import { isEmpty, without, uniq, concat, cloneDeep, some, every, flatMapDeep, reject, filter, differenceWith, isEqual } from "lodash";
import { appHealthStatus, gitopsStatus } from "./config";
import { getAppHealthIcon } from "./utils";

const HealthStatusFilter = ({...props}) => {
    return(
        <CheckboxGroup
            name="health-status-group"
            value={props.value}
            onChange={props.onChange}
            style={{ paddingLeft: "56px", paddingRight: "20px" }}
        >
            {Object.values(appHealthStatus).map((item, index) => {
                return <Checkbox value={item} key={`checkbox-${item}-${index}`}>
                            <Stack spacing="6px" justifyContent="space-between" alignItems="center">
                                <Stack spacing="8px" alignItems="center">
                                    {getAppHealthIcon(item, 18)}
                                    <b>{item}</b>
                                </Stack>
                                <b>{props.statusCount[item]}</b>
                            </Stack>
                        </Checkbox>
                    })}
        </CheckboxGroup>
    )
};

const EnvFilter = ({...props}) => {
    const [env, setEnv] = React.useState('');
    const [envData, setEnvData] = React.useState([]);
    const [clearInput, setClearInput] = React.useState(false)

    React.useEffect(() => {
        /* Create env data for multicascader */
        const envData = props.rawData.groups.map(grp => {
            const envNames = grp.environments.map(env => { return env.name })
            return envNames
        }).filter(Boolean).flat()
        setEnvData(envData)
    }, [props.rawData]);

    React.useEffect(() => {
        if (clearInput === true) {
            props.addEnv(env)
            setEnv('')
            setClearInput(false)
        }
    }, [clearInput, env, props]);

    const removeEnv = (value, checked) => {
        if (checked === false) {
		    props.removeEnv(value)
        }
	};

    return(
        <div style={{ paddingLeft: "56px", paddingRight: "20px" }}>
            <AutoComplete
                value={env}
                onChange={setEnv}
                onSelect={() => setClearInput(true)}
                placeholder="ENVIRONMENTS"
                data={envData}
            />
            <CheckboxGroup
                name="env-group"
                value={props.value}
            >
                {props.value.map((item, index) => {
                    return <Checkbox
                                value={item}
                                key={`checkbox-${item}-${index}`}
                                onChange={removeEnv}
                            >
                                <b>{item}</b>
                            </Checkbox>
                        })}
            </CheckboxGroup>
        </div>
    )
};

const Filter = ({...props}) => {
    const [healthStatus, setHealthStatus] = React.useState([]);
    const [healthStatusCount, setHealthStatusCount] = React.useState({});
    const [envFilter, setEnvFilter] = React.useState([]);
    
    const addEnvToFilter = (value) => {
		const newEnvFilter = [...envFilter]
        newEnvFilter.push(value)
        setEnvFilter(uniq(newEnvFilter.sort()))
	};

    const removeEnvFromFilter = (value) => {
		const newEnvFilter = [...envFilter]
        setEnvFilter(without(newEnvFilter, value))
	};

    React.useEffect(() => {
        const statusCount = {}
        for (let item in appHealthStatus) {
            statusCount[appHealthStatus[item]] = 0
        }

        const statusList = [...healthStatus]
        if (statusList.length === 0) {
            for (let item in appHealthStatus) {
                statusList.push(appHealthStatus[item])
            }
        }
        const cmpGroup = cloneDeep(props.rawData.groups[0])
        cmpGroup["name"] = "Compare Environments"
        cmpGroup["environments"] = []
        cmpGroup["synch"] = []
        cmpGroup["status"]["overall"] = gitopsStatus.GREEN

        let filteredGroups = []
        if (isEmpty(props.rawData) === false) {
            filteredGroups = props.rawData.groups.map(group => {
                const filteredEnvs = group.environments.map(env => {
                    if ((envFilter.length !== 0) &&
                        (envFilter.includes(env.name) === false)) {
                        return undefined
                    }
                    const filteredApps = env.applications.map(app => {
                        statusCount[app.argocd.status] += 1;
                        
                        if (statusList.includes(app.argocd.status) === true) {
                            return app
                        }
                        return undefined
                    }).filter(Boolean)
                    return filteredApps.length ?
                                { ...env, applications: env.applications } :
                                undefined
                }).filter(Boolean)

                return filteredEnvs.length ?
                                { ...group, environments: filteredEnvs } :
                                undefined
            }).filter(Boolean)
            setHealthStatusCount(statusCount)
            if ((envFilter.length === 0) || (isEmpty(filteredGroups) === true)) {
		        props.setFilteredData({groups: filteredGroups})
            } else {
                filteredGroups.forEach(group => {
                    cmpGroup["environments"] = concat(cmpGroup["environments"], group["environments"])
                    group["environments"].forEach(env => {
                        const newSynchArray = concat([],
                                                    flatMapDeep(
                                                        reject(env["applications"], ["name", "core-backend"]),
                                                        (x) => { return { [x.name]: x.sha } }
                                                    ),
                                                    flatMapDeep(
                                                        filter(env["applications"], ["name", "core-backend"]),
                                                        (x) => { 
                                                            return flatMapDeep(x.modules, (m) => {
                                                                return { [m.name]: m.sha }
                                                            })
                                                        }
                                                    )
                                            ).filter(Boolean)
                        if (cmpGroup["synch"].length === 0) {
                            cmpGroup["synch"] = newSynchArray
                            cmpGroup["status"]["synch"] = true
                        } else {
                            const diffArray = differenceWith(cmpGroup["synch"], newSynchArray, isEqual)
                            if (diffArray.length !== 0) {
                                cmpGroup["status"]["synch"] = false
                            }
                        }
                    })
                });

                if (every(cmpGroup["environments"], ["status", gitopsStatus.GREEN]) === false) {
                    if (some(cmpGroup["environments"], ["status", gitopsStatus.RED]) === true) {
                        cmpGroup["status"]["overall"] = gitopsStatus.RED
                    } else if (some(cmpGroup["environments"], ["status", gitopsStatus.AMBER]) === true) {
                        cmpGroup["status"]["overall"] = gitopsStatus.AMBER
                    }
                }
                props.setFilteredData({groups: [cmpGroup]})
            }
        } else {
            for (let item in appHealthStatus) {
                statusCount[appHealthStatus[item]] = 0
            }
            setHealthStatusCount(statusCount)
            props.setFilteredData(props.rawData)
        }
        if (envFilter.length === 0) {
            props.setCompareEnv(false)
        } else {
            props.setCompareEnv(true)
        }
        // eslint-disable-next-line
	}, [props.rawData, healthStatus, envFilter]);

	return (
        <Affix top={120} >
            <div style={{ width: "260px" }} >
                <Sidenav defaultOpenKeys={['1']} >
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Menu
                                eventKey="1"
                                title={<b>HEALTH STATUS</b>}
                            >
                                <Nav.Item
                                    eventKey="1-1"
                                    as={HealthStatusFilter}
                                    value={healthStatus}
                                    statusCount={healthStatusCount}
                                    onChange={setHealthStatus}
                                />
                            </Nav.Menu>
                            <Nav.Menu
                                eventKey="3"
                                title={<b>ENVIRONMENTS</b>}
                            >
                                <Nav.Item
                                    eventKey="3-1"
                                    as={EnvFilter}
                                    value={envFilter}
                                    rawData={props.rawData}
                                    addEnv={addEnvToFilter}
                                    removeEnv={removeEnvFromFilter}
                                />
                            </Nav.Menu>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div>
        </Affix>
	);
};

export default Filter;