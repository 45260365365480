import React from "react";
import { jobsCfg } from "./config.js";
import Manual from "./Manual";
import Approval from "./Approval";

const Jobs = ({...props}) => {
    return (
        <>
            {(props.type === jobsCfg.TYPE.MANUAL) && <Manual {...props} />}
            {(props.type === jobsCfg.TYPE.APPROVAL) && <Approval {...props} />}
        </>
    );
};
export default Jobs;