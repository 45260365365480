import React from "react";
import { SelectPicker } from "rsuite";
import { formCfg } from "../config";

const accessTypeOptions = [
  { value: 'r', label: 'Read' },
  { value: 'rw', label: 'Read-Write' },
  { value: 'rwd', label: 'Read-Write-Delete' }
];

const AccessTypePicker = ({...props}) => {

    const accessTypeChangeHandler = (value, event) => {
        if (value === null) {
            props.onChange([], props.name);
        } else {
            props.onChange(value, props.name);
        }
    };

    const accessTypeCleanHandler = (value, event) => {
        props.onChange([], props.name);
    };

    return (
        <SelectPicker
            data={accessTypeOptions}
            onChange={accessTypeChangeHandler}
            onClean={accessTypeCleanHandler}
            style={{ width: formCfg.WIDTH }} />
    );
};

export default AccessTypePicker;
